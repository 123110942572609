import {
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import { useApolloClient } from '@apollo/client'
import {
  Drawer,
  FormControl,
  FormHelperText,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { FLAG_FEATURES } from 'api/src/common/enums'
import { IconoirProvider } from 'iconoir-react'
import {
  CreateHubDashCard,
  CreateHubDashCardVariables,
  DeleteHubDashCard,
  DeleteHubDashCardVariables,
  UpdateHubDashCard,
  UpdateHubDashCardVariables,
  type CreateColor,
  type CreateColorVariables,
  type CreateHubDashCardToHubResourceInput,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import CardSettingsChartType from 'src/components/HubDash/CardSettingsDrawer/CardSettingsModules/CardSettingsChartType'
import CardSettingsSource from 'src/components/HubDash/CardSettingsDrawer/CardSettingsModules/CardSettingsSource'
import ColorGrid from 'src/components/HubDash/CardSettingsDrawer/CardSettingsModules/ColorGrid/ColorGrid'
import CardPreview from 'src/components/HubDash/HubDashCard/CardPreview'
import {
  QUERY,
  type HubDashLinkedContent,
} from 'src/components/HubDash/HubDashLayoutListCell/HubDashLayoutListCell'
import {
  getFieldsForTable,
  getUserBasesAndTables,
  getViewsForTable,
} from 'src/components/HubDash/lib/baserow/baserowApi'
import {
  CREATE_COLOR,
  UPDATE_HUBDASH_CARD,
  CREATE_HUBDASH_CARD,
  DELETE_HUBDASH_CARD,
} from 'src/components/HubDash/queries'
import Accordion from 'src/components/Library/Accordion/Accordion'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import Switch from 'src/components/Library/Switch/Switch'
import Tab from 'src/components/Library/Tab'
import Tabs from 'src/components/Library/Tabs'
import useLiveFeature from 'src/lib/hooks/LiveFeatureFlag/UseLiveFeature'
import useHubDashStore from 'src/lib/stores/hubDashStore'

import type { BaserowColor } from '../lib/baserow/baserowColors'
import { CardType } from '../lib/enums'
import type { BaserowReference, HubDashCardType } from '../lib/types'

import BarChartDataSettings from './ChartSettings/BarChartDataSettings'
import ChartAppearanceSettings from './ChartSettings/ChartAppearanceSettings'
import PieChartDataSettings from './ChartSettings/PieChartDataSettings'
import FieldSummarySettings, { SummaryType } from './FieldSummarySettings'
import LinkLearningContent from './LinkLearningContent/LinkLearningContent'

export type CardFormErrors = {
  name: string
  base: string
  table: string
  view: string
  category: string
  aggregateField: string
  recordFilterField: string
}

export enum DisplayUnits {
  NONE = 'none',
  PERCENT = 'percent',
  CURRENCY = 'currency',
  CUSTOM = 'custom',
}

enum SettingsTab {
  DATA = 'data',
  APPEARANCE = 'appearance',
  LEARNING = 'learning',
}

interface CardSettingsDrawerProps {
  cardSettingsOpen: boolean
  setCardSettingsOpen: Dispatch<SetStateAction<boolean>>
  settingsCard: HubDashCardType
}

const CardSettingsDrawer: FC<CardSettingsDrawerProps> = ({
  cardSettingsOpen,
  setCardSettingsOpen,
  settingsCard: card,
}) => {
  const [linkedLearningContentEnabled] = useLiveFeature(
    FLAG_FEATURES.WORKFLOWS_LEARNING_CONTENT,
  )

  const client = useApolloClient()

  const DEFAULT_CARD_ERRORS: CardFormErrors = {
    name: '',
    base: '',
    table: '',
    view: '',
    category: '',
    aggregateField: '',
    recordFilterField: '',
  }

  // Form Errors
  const [formErrors, setFormErrors] =
    useState<CardFormErrors>(DEFAULT_CARD_ERRORS)

  const formFieldsInvalid =
    !!formErrors?.name ||
    !!formErrors?.base ||
    !!formErrors?.table ||
    !!formErrors?.view ||
    !!formErrors?.category ||
    !!formErrors?.aggregateField ||
    !!formErrors?.recordFilterField

  const [token, layout, isUnlocked] = useHubDashStore((state) => [
    state.token,
    state.layout,
    state.isUnlocked,
    state.layoutData,
  ])

  const [activeTab, setActiveTab] = useState<SettingsTab>(SettingsTab.DATA)

  const [basesList, setBasesList] = useState([])
  const [tablesList, setTablesList] = useState([])
  const [viewsList, setViewsList] = useState([])
  const [fieldsList, setFieldsList] = useState([])

  const [selectedBase, setSelectedBase] = useState(card?.cardData?.base || null)
  const [selectedTable, setSelectedTable] = useState(
    card?.cardData?.table || null,
  )
  const [selectedView, setSelectedView] = useState(card?.cardData?.view || null)
  const [enteredCardName, setEnteredCardName] = useState(card?.name)
  const [enteredCardDescription, setEnteredCardDescription] = useState(
    card?.description,
  )

  const [hasUserFilter, setHasUserFilter] = useState(
    card?.cardSettings?.filters?.user ? true : false,
  )
  const [userFilterField, setUserFilterField] = useState(
    card?.cardSettings?.filters?.user || null,
  )

  const [recordFilter, setRecordFilter] = useState(
    card?.cardSettings?.filters?.counter?.count || 'colors',
  )

  const [displayUnits, setDisplayUnits] = useState<DisplayUnits>(
    card?.cardSettings?.filters?.counter?.displayUnits || DisplayUnits.NONE,
  )

  const [cardType, setCardType] = useState<CardType>(
    (card?.type as CardType) || null,
  )

  const [backgroundColor, setBackgroundColor] = useState<BaserowColor>(null)
  const [textColor, setTextColor] = useState<BaserowColor>(null)

  const [chartSettings, setChartSettings] = useState(
    card?.cardSettings?.chartSettings || null,
  )
  const [chartCategory, setChartCategory] = useState(
    card?.cardSettings?.chartSettings?.category || null,
  )

  const [summaryField, setSummaryField] = useState<BaserowReference>(
    card?.cardSettings?.filters?.counter?.field ?? null,
  )
  const [summaryType, setSummaryType] = useState<SummaryType>(
    card?.cardSettings?.filters?.counter?.summaryType ?? SummaryType.EMPTY,
  )

  const [loadingBases, setLoadingBases] = useState(false)
  const [loadingViews, setLoadingViews] = useState(false)

  const [linkedLearningContent, setLinkedLearningContent] = useState<
    HubDashLinkedContent[]
  >(
    card?.linkedResources?.map((link) => {
      return {
        resourceId: link?.resourceId,
        resourceName: link?.resourceName,
        resourceType: link?.resourceType,
        linkMetaData: link?.linkMetaData,
      }
    }) ?? [],
  )

  const [allowShowHiddenFields, setAllowShowHiddenFields] = useState(
    card?.cardSettings?.hiddenFields?.allowShowHiddenFields || false,
  )

  const handleClose = () => {
    setCardSettingsOpen(false)
  }

  const [updateCard, { loading: updateCardLoading }] = useMutation<
    UpdateHubDashCard,
    UpdateHubDashCardVariables
  >(UPDATE_HUBDASH_CARD)

  const [createCard, { loading: createCardLoading }] = useMutation<
    CreateHubDashCard,
    CreateHubDashCardVariables
  >(CREATE_HUBDASH_CARD)

  const [duplicateCard, { loading: duplicateCardLoading }] = useMutation<
    CreateHubDashCard,
    CreateHubDashCardVariables
  >(CREATE_HUBDASH_CARD)

  const [duplicateColor, { loading: duplicateColorLoading }] = useMutation<
    CreateColor,
    CreateColorVariables
  >(CREATE_COLOR)

  const [deleteCard, { loading: deleteCardLoading }] = useMutation<
    DeleteHubDashCard,
    DeleteHubDashCardVariables
  >(DELETE_HUBDASH_CARD, {
    onCompleted: () => {
      setCardSettingsOpen(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: QUERY, fetchPolicy: 'network-only' }],
  })

  useEffect(() => {
    // Reset Form
    setFormErrors(DEFAULT_CARD_ERRORS)

    if (cardSettingsOpen) {
      window.Intercom('update', {
        alignment: 'left',
      })
    } else {
      window.Intercom('update', {
        alignment: 'right',
      })
    }
  }, [cardSettingsOpen])

  const prevSelectedBase = useRef(null)
  const prevSelectedTable = useRef(null)
  useEffect(() => {
    setActiveTab(SettingsTab.DATA)
    setEnteredCardName(card?.name || '')
    setEnteredCardDescription(card?.description || '')
    const selectedBase = card?.cardData?.base
      ? {
          ...card?.cardData?.base,
          base: { ...card?.cardData?.base },
          workspace: { ...card?.cardData?.workspace },
        }
      : null
    prevSelectedBase.current = selectedBase
    setSelectedBase(selectedBase)
    prevSelectedTable.current = card?.cardData?.table || null
    setSelectedTable(card?.cardData?.table || null)
    setSelectedView(card?.cardData?.view || null)
    setBackgroundColor(card?.cardSettings?.appearance?.backgroundColor || '')
    setTextColor(card?.cardSettings?.appearance?.textColor || '')
    setHasUserFilter(card?.cardSettings?.filters?.user ? true : false)
    setUserFilterField(card?.cardSettings?.filters?.user || null)
    setRecordFilter(card?.cardSettings?.filters?.counter?.count || 'colors')
    setDisplayUnits(
      card?.cardSettings?.filters?.counter?.displayUnits || DisplayUnits.NONE,
    )
    setCardType((card?.type as CardType) || null)
    setChartSettings(card?.cardSettings?.chartSettings || null)
    setChartCategory(card?.cardSettings?.chartSettings?.category || null)
    setSummaryField(card?.cardSettings?.filters?.counter?.field || null)
    setSummaryType(
      card?.cardSettings?.filters?.counter?.summaryType || SummaryType.EMPTY,
    )
    setLinkedLearningContent(
      card?.linkedResources?.map((link) => {
        return {
          resourceId: link?.resourceId,
          resourceName: link?.resourceName,
          resourceType: link?.resourceType,
          linkMetaData: link?.linkMetaData,
        }
      }) ?? [],
    )
    setAllowShowHiddenFields(
      card?.cardSettings?.hiddenFields?.allowShowHiddenFields || false,
    )
  }, [card, cardSettingsOpen])

  useEffect(() => {
    const getBasesAndTables = async () => {
      const basesData = await getUserBasesAndTables({ token })
      setBasesList(basesData)
    }
    if (token) {
      getBasesAndTables()
    }
  }, [token])

  useEffect(() => {
    if (
      prevSelectedBase.current &&
      prevSelectedBase.current.id !== selectedBase?.id
    ) {
      setTablesList(selectedBase?.tables || [])
      setViewsList([])
      setSelectedTable(null)
      setSummaryField(null)
      setSelectedView(null)
      setUserFilterField(null)
      setChartCategory(null)
    }

    if (selectedBase) {
      const tablesData =
        basesList.find((base) => base.id === selectedBase.id)?.tables || []
      setTablesList(tablesData)
      prevSelectedBase.current = selectedBase
    }
  }, [selectedBase])

  useEffect(() => {
    if (
      prevSelectedTable.current &&
      prevSelectedTable.current.id !== selectedTable?.id
    ) {
      setViewsList([])
      setSelectedView(null)
      setUserFilterField(null)
      setChartCategory(null)
    }
    if (selectedTable) {
      setLoadingViews(true)
      const getViewsAndFields = async () => {
        const viewsData = await getViewsForTable({
          token,
          tableId: selectedTable.id,
        })
        const fieldsData = await getFieldsForTable({
          token,
          tableId: selectedTable.id,
        })
        setViewsList(viewsData)
        setFieldsList(fieldsData)
        setLoadingViews(false)
      }
      getViewsAndFields()
      prevSelectedTable.current = selectedTable
    }
  }, [selectedTable])

  useEffect(() => {
    // clear form error if the user is not on the field filter
    if (recordFilter !== 'field' && formErrors.recordFilterField) {
      setFormErrors({ ...formErrors, recordFilterField: '' })
    }
  }, [recordFilter])

  const handleSave = async () => {
    let formIsValid = true

    const validation: CardFormErrors = {
      name: '',
      base: '',
      table: '',
      view: '',
      category: '',
      aggregateField: '',
      recordFilterField: '',
    }

    if (!enteredCardName) {
      validation.name = 'Layout Card must have a name.'
      formIsValid = false
    }

    if (cardType !== CardType.HEADING) {
      if (!selectedBase) {
        validation.base = 'A base must be selected.'
        formIsValid = false
      }

      if (!selectedTable) {
        validation.table = 'A table must be selected.'
        formIsValid = false
      }

      if (!selectedView) {
        validation.view = 'A view must be selected.'
        formIsValid = false
      }

      if (recordFilter === 'field' && !summaryField) {
        validation.recordFilterField = 'A field must be selected.'
        formIsValid = false
      }
    }

    // Chart Types must have a category set
    if (
      [CardType.CHART_BAR, CardType.CHART_PIE].includes(cardType) &&
      !chartCategory
    ) {
      validation.category = 'A category must be selected.'
      formIsValid = false
    }
    // if chart is bar, fieldSummary is on, must have a field selected
    if (
      cardType === CardType.CHART_BAR &&
      chartSettings?.barChart?.viewAxis?.type === 'fieldSummary' &&
      !chartSettings?.barChart?.viewAxis?.field
    ) {
      validation.aggregateField = 'A field must be selected.'
      formIsValid = false
    }

    setFormErrors(validation)

    const linkedContentInput: CreateHubDashCardToHubResourceInput[] =
      linkedLearningContent?.map((link) => {
        return {
          resourceId: link?.resourceId,
          resourceName: link?.resourceName,
          resourceType: link?.resourceType,
          linkMetaData: link?.linkMetaData,
          hubDashCardId: card.id,
        }
      })
    if (formIsValid) {
      if (card?.cardData) {
        if (cardType === CardType.HEADING) {
          await updateCard({
            variables: {
              id: card.id,
              relationId: card?.relationId,
              input: {
                name: enteredCardName,
                type: CardType.HEADING,
                cardData: {},
                cardSettings: {
                  appearance: {
                    backgroundColor: backgroundColor,
                    textColor: textColor,
                  },
                },
              },
            },
          })
        } else {
          await updateCard({
            variables: {
              id: card.id,
              relationId: card?.relationId,
              input: {
                name: enteredCardName,
                description: enteredCardDescription,
                type: cardType,
                source: 'baserow',
                cardData: {
                  workspace: {
                    id: selectedBase.workspace.id,
                    name: selectedBase.workspace.name,
                  },
                  base: {
                    id: selectedBase.id,
                    name: selectedBase.name,
                  },
                  table: {
                    id: selectedTable.id,
                    name: selectedTable.name,
                  },
                  view: {
                    id: selectedView.id,
                    name: selectedView.name,
                  },
                },
                cardSettings: {
                  hiddenFields: {
                    allowShowHiddenFields: allowShowHiddenFields,
                  },
                  chartSettings: chartSettings,
                  appearance: {
                    backgroundColor: backgroundColor,
                    textColor: textColor,
                  },
                  filters: {
                    counter: {
                      count: recordFilter,
                      field: summaryField,
                      summaryType: summaryType,
                      displayUnits: displayUnits,
                    },
                    user: hasUserFilter ? userFilterField : null,
                  },
                },
              },
              linkedResources: linkedContentInput,
            },
          })
        }
      } else {
        const newCardPosition = {
          x: 0,
          y: 50,
          w: 1,
          h: 4,
        }
        if (cardType === CardType.HEADING) {
          newCardPosition.w = 2
          newCardPosition.h = 1
          await createCard({
            variables: {
              input: {
                name: enteredCardName,
                type: CardType.HEADING,
                cardData: {},
                cardSettings: {
                  appearance: {
                    backgroundColor: backgroundColor,
                    textColor: textColor,
                  },
                },
                hubDashLayoutId: layout,
                position: newCardPosition,
              },
            },
          })
        } else {
          if (
            [CardType.CHART_BAR, CardType.CHART_PIE].includes(
              cardType as CardType,
            )
          ) {
            newCardPosition.w = 2
            newCardPosition.h = 8
          }
          await createCard({
            variables: {
              input: {
                name: enteredCardName,
                description: enteredCardDescription,
                type: cardType,
                source: 'baserow',
                hubDashLayoutId: layout,
                position: newCardPosition,
                cardData: {
                  workspace: {
                    id: selectedBase.workspace.id,
                    name: selectedBase.workspace.name,
                  },
                  base: {
                    id: selectedBase.id,
                    name: selectedBase.name,
                  },
                  table: {
                    id: selectedTable.id,
                    name: selectedTable.name,
                  },
                  view: {
                    id: selectedView.id,
                    name: selectedView.name,
                  },
                },
                cardSettings: {
                  hiddenFields: {
                    allowShowHiddenFields: allowShowHiddenFields,
                  },
                  chartSettings: chartSettings,
                  appearance: {
                    backgroundColor: backgroundColor,
                    textColor: textColor,
                  },
                  filters: {
                    counter: {
                      count: recordFilter,
                      field: summaryField,
                      summaryType: summaryType,
                      displayUnits: displayUnits,
                    },
                    user: hasUserFilter ? userFilterField : null,
                  },
                },
              },
              linkedResources: linkedContentInput,
            },
          })
        }
      }
      client.refetchQueries({ include: [QUERY] })
      setCardSettingsOpen(false)
    }
  }

  const handleDuplicate = async () => {
    const newCardPosition = {
      x: 0,
      y: 50,
      w: 1,
      h: 4,
    }
    if (
      [CardType.CHART_BAR, CardType.CHART_PIE].includes(card.type as CardType)
    ) {
      newCardPosition.w = 2
      newCardPosition.h = 8
    }
    const linkedContentInput: CreateHubDashCardToHubResourceInput[] =
      card.linkedResources?.map((link) => {
        return {
          resourceId: link?.resourceId,
          resourceName: link?.resourceName,
          resourceType: link?.resourceType,
          linkMetaData: link?.linkMetaData,
          hubDashCardId: card.id,
        }
      })
    const createCardRes = await duplicateCard({
      variables: {
        input: {
          name: `Duplicate - ${card.name}`,
          description: card.description,
          type: card.type,
          source: 'baserow',
          hubDashLayoutId: card.hubDashLayoutId,
          position: newCardPosition,
          cardData: card.cardData,
          cardSettings: card.cardSettings,
        },
        linkedResources: linkedContentInput,
      },
    })
    const newCardId = createCardRes.data?.createHubDashCard?.id
    if (card.cardColors?.length > 0) {
      await Promise.all(
        card.cardColors.map(async (color) => {
          await duplicateColor({
            variables: {
              input: {
                name: color.name,
                description: color.description,
                colorName: color.colorName,
                icon: color.icon,
                type: color.type,
                hubDashCardId: newCardId,
              },
            },
          })
        }),
      )
    }
    client.refetchQueries({ include: [QUERY] })
    setCardSettingsOpen(false)
  }

  const handleDelete = () => {
    deleteCard({
      variables: {
        id: card.id,
      },
    })
  }

  let cleanType = cardType as string

  switch (cleanType) {
    case CardType.HEADING:
      cleanType = 'Heading'
      break
    case CardType.COUNTER:
      cleanType = 'Count'
      break
    case CardType.CHART_BAR:
      cleanType = 'Bar Chart'
      break
    case CardType.CHART_PIE:
      cleanType = 'Pie Chart'
      break
  }

  const StyledToggleButton = ({ value, label }) => (
    <ToggleButton
      value={value}
      className="capitalize leading-6"
      data-testid={`hubdash-select-count-type-${value}`}
    >
      {label}
    </ToggleButton>
  )

  const countToggleOptions = [
    { value: 'colors', label: 'Colours' },
    { value: 'records', label: 'Records' },
    { value: 'field', label: 'Field' },
  ]

  const countUnitOptions = [
    { value: DisplayUnits.NONE, label: 'None' },
    { value: DisplayUnits.PERCENT, label: '%' },
    { value: DisplayUnits.CURRENCY, label: '$' },
  ]

  const summaryTypeDoesNotNeedCustomSymbol =
    recordFilter === 'colors' ||
    recordFilter === 'records' ||
    summaryType === SummaryType.EMPTY ||
    summaryType === SummaryType.FILLED ||
    summaryType === SummaryType.PERCENT_EMPTY ||
    summaryType === SummaryType.PERCENT_FILLED ||
    summaryType === SummaryType.EARLIEST_DATE ||
    summaryType === SummaryType.LATEST_DATE

  // When chartCategory gets cleared from base/table - reset to avoid preview issues
  useEffect(() => {
    if (!chartCategory) {
      setChartSettings({
        ...chartSettings,
        barChart: {
          ...chartSettings?.barChart,
          viewAxis: {
            ...chartSettings?.barChart?.viewAxis,
            // Reset the Group By
            groupBy: null,
            field: null,
          },
        },
        // Reset the Selected Category
        category: null,
      })
    }
  }, [chartCategory])

  useEffect(() => {
    if (enteredCardName) {
      setFormErrors({ ...formErrors, name: '' })
    }
  }, [enteredCardName])

  const isCardProcessing =
    createCardLoading ||
    updateCardLoading ||
    duplicateCardLoading ||
    duplicateColorLoading ||
    deleteCardLoading

  return (
    <>
      {cardSettingsOpen && (
        <>
          <div
            className={
              'absolute left-0 top-0 flex h-full w-full items-center justify-center'
            }
            style={{ width: 'calc(100% - 600px)', height: '100%' }}
          >
            <CardPreview
              card={{
                ...card,
                type: cardType,
                name: enteredCardName,
                description: enteredCardDescription,
                cardData: {
                  base: selectedBase,
                  table: selectedTable,
                  view: selectedView,
                },
                cardSettings: {
                  chartSettings: chartSettings,
                  appearance: {
                    backgroundColor: backgroundColor,
                    textColor: textColor,
                  },
                  filters: {
                    counter: {
                      count: recordFilter,
                      field: summaryField,
                      summaryType: summaryType,
                      displayUnits: displayUnits,
                    },
                    user: hasUserFilter ? userFilterField : null,
                  },
                },
              }}
              setSettingsCard={() => {}}
              setCardSettingsOpen={() => {}}
            />
          </div>
        </>
      )}
      <Drawer
        anchor={'right'}
        open={cardSettingsOpen}
        onClose={() => handleClose()}
        PaperProps={{
          style: {
            width: '600px',
          },
        }}
      >
        <div className="flex h-full w-full flex-col overflow-auto">
          <div className="flex flex-col gap-4 p-4">
            <p className="text-2xl text-gray-500">{cleanType}</p>
            <FormControl fullWidth error={!!formErrors.name}>
              <TextField
                required
                fullWidth
                error={!!formErrors?.name}
                disabled={isCardProcessing}
                label="Name"
                value={enteredCardName}
                onChange={(event) => setEnteredCardName(event.target.value)}
                data-testid="card-name-input"
              />
              <FormHelperText>{formErrors.name}</FormHelperText>
            </FormControl>
            {card?.type !== CardType.HEADING && (
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  disabled={isCardProcessing}
                  label="Description"
                  value={enteredCardDescription}
                  onChange={(event) =>
                    setEnteredCardDescription(event.target.value)
                  }
                  data-testid="card-description-input"
                />
              </FormControl>
            )}
          </div>
          {cardType?.includes('chart') && (
            <CardSettingsChartType
              chartType={cardType}
              setChartType={setCardType}
            />
          )}
          {cardType !== CardType.HEADING && (
            <Tabs
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue as SettingsTab)}
              variant="fullWidth"
            >
              <Tab label="Data" value={SettingsTab.DATA} />
              <Tab label="Appearance" value={SettingsTab.APPEARANCE} />
              {linkedLearningContentEnabled && (
                <Tab label="Linked Content" value={SettingsTab.LEARNING} />
              )}
            </Tabs>
          )}
          {activeTab === SettingsTab.DATA && cardType !== CardType.HEADING && (
            <div className="flex flex-col gap-2 p-4">
              <CardSettingsSource
                basesList={basesList}
                selectedBase={selectedBase}
                setSelectedBase={setSelectedBase}
                tablesList={tablesList}
                selectedTable={selectedTable}
                setSelectedTable={setSelectedTable}
                viewsList={viewsList}
                selectedView={selectedView}
                setSelectedView={setSelectedView}
                loadingViews={loadingViews}
                loadingBases={loadingBases}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
              <Accordion title={'Filters'} expanded>
                <div className="flex flex-col gap-4">
                  <div className="flex w-full items-center justify-between">
                    <p>User Filtering</p>
                    <Switch
                      checked={hasUserFilter}
                      onChange={(e) => setHasUserFilter(e.target.checked)}
                    />
                  </div>
                  {hasUserFilter && (
                    <Autocomplete
                      options={fieldsList}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField {...params} label="Field" />
                      )}
                      value={userFilterField}
                      onChange={(_, newValue) => setUserFilterField(newValue)}
                      loading={loadingViews}
                      disabled={!selectedTable}
                    />
                  )}
                </div>
              </Accordion>
              <Accordion title={'Data'} expanded>
                {cardType === CardType.COUNTER && (
                  <div className="flex flex-col gap-2">
                    <p>Count Type</p>
                    <ToggleButtonGroup
                      value={recordFilter}
                      exclusive
                      onChange={(_, newValue) =>
                        newValue !== null && setRecordFilter(newValue)
                      }
                      fullWidth
                    >
                      {countToggleOptions.map((option) => (
                        <StyledToggleButton
                          key={option.value}
                          value={option.value}
                          label={option.label}
                        />
                      ))}
                    </ToggleButtonGroup>
                    {recordFilter === 'field' && (
                      <FieldSummarySettings
                        fieldsList={fieldsList}
                        loadingViews={loadingViews}
                        selectedTable={selectedTable}
                        selectedView={selectedView}
                        summaryField={summaryField}
                        setSummaryField={setSummaryField}
                        summaryType={summaryType}
                        setSummaryType={setSummaryType}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                      />
                    )}
                  </div>
                )}
                {(cardType === CardType.CHART_PIE ||
                  cardType === CardType.CHART_BAR) && (
                  <div className="flex flex-col gap-4 pt-1">
                    {cardType === CardType.CHART_PIE && (
                      <PieChartDataSettings
                        fieldsList={fieldsList}
                        chartSettings={chartSettings}
                        setChartSettings={setChartSettings}
                        chartCategory={chartCategory}
                        setChartCategory={setChartCategory}
                        loadingViews={loadingViews}
                        selectedTable={selectedTable}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                      />
                    )}
                    {cardType === CardType.CHART_BAR && (
                      <BarChartDataSettings
                        fieldsList={fieldsList}
                        chartSettings={chartSettings}
                        setChartSettings={setChartSettings}
                        chartCategory={chartCategory}
                        setChartCategory={setChartCategory}
                        loadingViews={loadingViews}
                        selectedTable={selectedTable}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                      />
                    )}
                  </div>
                )}
              </Accordion>
              <Accordion title={'Hidden Fields'} expanded>
                <div className="flex flex-col gap-4">
                  <div className="flex w-full items-center justify-between">
                    <p>Allow user to see hidden fields</p>
                    <Switch
                      checked={allowShowHiddenFields}
                      onChange={(e) =>
                        setAllowShowHiddenFields(e.target.checked)
                      }
                    />
                  </div>
                </div>
              </Accordion>
            </div>
          )}
          {(activeTab === SettingsTab.APPEARANCE ||
            cardType === CardType.HEADING) && (
            <div className="flex flex-col gap-4 p-4">
              {cardType.includes('chart') && (
                <ChartAppearanceSettings
                  cardType={cardType}
                  chartSettings={chartSettings}
                  setChartSettings={setChartSettings}
                />
              )}
              {cardType === CardType.COUNTER &&
                !summaryTypeDoesNotNeedCustomSymbol && (
                  <Accordion title={'Display Units'} expanded>
                    <div className="flex flex-col gap-2">
                      <ToggleButtonGroup
                        value={displayUnits}
                        disabled={summaryTypeDoesNotNeedCustomSymbol}
                        exclusive
                        onChange={(_, newValue) =>
                          newValue !== null && setDisplayUnits(newValue)
                        }
                        fullWidth
                      >
                        {countUnitOptions.map((option) => (
                          <StyledToggleButton
                            key={option.value}
                            value={option.value}
                            label={option.label}
                          />
                        ))}
                      </ToggleButtonGroup>
                    </div>
                  </Accordion>
                )}
              <Accordion title={'Colours'} expanded>
                <div className="flex flex-col gap-2 rounded border border-gray-200 p-2">
                  <p>Background Colour</p>
                  <ColorGrid
                    selectedColor={backgroundColor}
                    setSelectedColor={setBackgroundColor}
                  />
                </div>
                <div className="mt-2 flex flex-col gap-2 rounded border border-gray-200 p-2">
                  <p>Text Colour</p>
                  <ColorGrid
                    selectedColor={textColor}
                    setSelectedColor={setTextColor}
                  />
                </div>
              </Accordion>
            </div>
          )}
          {activeTab === SettingsTab.LEARNING &&
            cardType !== CardType.HEADING && (
              <LinkLearningContent
                linkedLearningContent={linkedLearningContent}
                setLinkedLearningContent={setLinkedLearningContent}
              />
            )}
          <div className="sticky bottom-0 z-10 flex justify-between gap-2 border-t bg-white p-4">
            <div className="flex gap-2">
              {card?.id && isUnlocked && (
                <Button
                  color="error"
                  onClick={() => handleDelete()}
                  loading={deleteCardLoading}
                  disabled={isCardProcessing}
                >
                  {cardType === CardType.HEADING ? 'Delete' : 'Remove'}
                </Button>
              )}
              {card?.id && isUnlocked && cardType !== CardType.HEADING && (
                <Button
                  onClick={() => handleDuplicate()}
                  loading={duplicateCardLoading || duplicateColorLoading}
                  disabled={isCardProcessing}
                >
                  Duplicate
                </Button>
              )}
            </div>

            <div className="flex gap-2">
              <Button
                variant="text"
                onClick={() => handleClose()}
                disabled={isCardProcessing}
              >
                Cancel
              </Button>
              <Button
                disabled={formFieldsInvalid || isCardProcessing}
                loading={createCardLoading || updateCardLoading}
                onClick={handleSave}
                buttonDataTestId={`hubdash-${card?.cardData ? 'save' : 'create'}-card`}
              >
                {card?.cardData ? 'Save' : 'Create'}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default CardSettingsDrawer
