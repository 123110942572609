import dayjs, { type Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { clamp, median, sum } from 'ramda'

import { getHexForColor } from '../../lib/baserow/baserowColors'
import { CHART_EMPTY_COLOR } from '../../lib/constants'
import { BucketByValues, DateFormatType } from '../../lib/enums'
import type {
  AnyBaserowField,
  BarChartAggregateType,
  BarChartOrder,
  BarChartSort,
} from '../../lib/types'

import type { ChartItem } from './getChartData'
import { getValueAsNumber } from './getChartData'
import { getFieldDisplayValues } from './getFieldDisplayValues'
import { MAX_DECIMAL_COUNT } from './summaryFunctions'

dayjs.extend(customParseFormat)

const clampValue = (
  valueToClamp: number,
  dataField: AnyBaserowField,
): number => {
  return Number(
    Number(valueToClamp).toFixed(
      clamp(
        0,
        MAX_DECIMAL_COUNT,
        dataField?.number_decimal_places ?? MAX_DECIMAL_COUNT,
      ),
    ),
  )
}

const convertStringToDayJsWithFieldFormat = (
  dateString: string,
  baserowField: AnyBaserowField,
): Dayjs => {
  const dateFormat = baserowField?.date_format
  if (!dateFormat || !['EU', 'ISO', 'US'].includes(dateFormat)) {
    return dayjs(dateString).tz() // tz() works properly here because it's applied after creating the Day.js object.
  }
  const formatUsedToParse = DateFormatType[dateFormat]
  const timeFormat = baserowField?.date_include_time
    ? baserowField?.date_time_format === '24'
      ? ' HH:mm'
      : ' hh:mma'
    : ''
  //dayjs(dateString, format).tz() does NOT apply the timezone correction because the date was already parsed as UTC.
  return dayjs.tz(
    dateString,
    `${formatUsedToParse}${timeFormat}`,
    dayjs.tz.guess(),
  )
}
const convertDayJsToDayWithHour = (
  dayJSObj: Dayjs,
  baserowField: AnyBaserowField,
) => {
  const dateFormat = DateFormatType[baserowField?.date_format]
  const timeFormat = baserowField?.date_include_time
    ? baserowField?.date_time_format === '24'
      ? ' HH:00'
      : ' hh:00 A'
    : ''
  return dayJSObj.format(`${dateFormat}${timeFormat}`)
}
const convertDayJsToHour = (dayJSObj: Dayjs, baserowField: AnyBaserowField) => {
  const timeFormat =
    baserowField?.date_time_format === '24' ? 'HH:00' : 'hh:00 A'
  return dayJSObj.format(timeFormat)
}

const convertDayJsToDay = (dayJSObj: Dayjs, baserowField) => {
  const dateFormat = DateFormatType[baserowField?.date_format]
  return dayJSObj.format(dateFormat)
}

const getBucketByString = (
  date: Dayjs,
  bucketByValue: BucketByValues,
  dataField: AnyBaserowField,
): string => {
  switch (bucketByValue) {
    case BucketByValues.HOUR:
      return `${String(convertDayJsToDayWithHour(date, dataField))}`
    case BucketByValues.DAY:
      return `${String(convertDayJsToDay(date, dataField))}`
    case BucketByValues.WEEK:
      return `${String(date?.startOf('week').format('DD MMM'))} - ${String(date.endOf('week').format('DD MMM'))}, ${String(date?.year())}`
    case BucketByValues.MONTH_OF_YEAR:
      return `${String(date?.format('MMMM'))}`
    case BucketByValues.QUARTER_OF_YEAR:
      return `Q${String(date?.quarter())}`
    case BucketByValues.YEAR:
      return `${String(date?.year())}`
    case BucketByValues.HOUR_OF_DAY:
      return `${String(convertDayJsToHour(date, dataField))}`
    case BucketByValues.DAY_OF_WEEK:
      return `${String(date?.format('dddd'))}`
    case BucketByValues.MONTH:
      return `${String(date?.format('MMMM'))}, ${String(date?.year())}`
    case BucketByValues.QUARTER:
      return `Q${String(date?.quarter())}, ${String(date?.year())}`
    default:
      return 'Empty'
  }
}

const getIntervalsExcludingChartItems = (
  chartItemsDates: Dayjs[],
  bucketByValue: BucketByValues,
  dataField: AnyBaserowField,
): string[] => {
  if (chartItemsDates.length === 0) return []

  // For dayOfWeek, monthOfYear, quarterOfYear, find the missing intervals by simplify comparing with the chartItemsDates to optimize performance
  if (bucketByValue === BucketByValues.DAY_OF_WEEK) {
    const dayOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const chartItemsDaysOfWeek = chartItemsDates.map((date) =>
      date.format('dddd'),
    )
    const missingDaysOfWeek = dayOfWeek.filter(
      (day) => !chartItemsDaysOfWeek.includes(day),
    )
    return missingDaysOfWeek
  } else if (bucketByValue === BucketByValues.MONTH_OF_YEAR) {
    const monthOfYear = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const chartItemsMonthsOfYear = chartItemsDates.map((date) =>
      date.format('MMMM'),
    )
    const missingMonthsOfYear = monthOfYear.filter(
      (month) => !chartItemsMonthsOfYear.includes(month),
    )
    return missingMonthsOfYear
  } else if (bucketByValue === BucketByValues.QUARTER_OF_YEAR) {
    const chartItemsQuarterOfYear = chartItemsDates.map(
      (date) => `Q${date.quarter()}`,
    )
    const missingQuarterOfYear = ['Q1', 'Q2', 'Q3', 'Q4'].filter(
      (quarter) => !chartItemsQuarterOfYear.includes(quarter),
    )
    return missingQuarterOfYear
  } else if (bucketByValue === BucketByValues.HOUR_OF_DAY) {
    const hourOfDay = Array.from(
      { length: 24 },
      (_, i) => `${String(i).padStart(2, '0')}:00`,
    ) // "00:00" to "23:00"
    const chartItemsHourOfDay = new Set(
      chartItemsDates.map((date) => `${date.format('HH')}:00`),
    )
    const missingHourOfDay = hourOfDay.filter(
      (hour) => !chartItemsHourOfDay.has(hour),
    )
    return missingHourOfDay
  }

  // For other bucketByValues, find the missing intervals
  // Sort dates and get the earliest and latest date
  const sortedDates = chartItemsDates.sort((a, b) => a.valueOf() - b.valueOf())
  const startDate = sortedDates[0]
  const endDate = sortedDates[sortedDates.length - 1]

  // Convert chartItemsDates to a Set of formatted bucket strings to exclude them
  const excludedBuckets = new Set(
    chartItemsDates.map((date) =>
      getBucketByString(date, bucketByValue, dataField),
    ),
  )

  let current = startDate.clone()
  const intervals: string[] = []
  const seenBuckets = new Set<string>() // Track already added values

  while (current.isBefore(endDate) || current.isSame(endDate)) {
    const formattedBucket = getBucketByString(current, bucketByValue, dataField)

    // Only add if it's NOT in excludedBuckets and hasn't been added before
    if (
      !excludedBuckets.has(formattedBucket) &&
      !seenBuckets.has(formattedBucket)
    ) {
      intervals.push(formattedBucket)
      seenBuckets.add(formattedBucket) // Mark as added
    }

    // Stop when we reach 100 intervals
    if (intervals.length > 100) {
      return intervals
    }

    switch (bucketByValue) {
      case BucketByValues.HOUR:
        current = current.add(1, 'hour')
        break
      case BucketByValues.DAY:
        current = current.add(1, 'day')
        break
      case BucketByValues.WEEK:
        current = current.add(1, 'week').startOf('week')
        break
      case BucketByValues.MONTH:
        current = current.add(1, 'month').startOf('month')
        break
      case BucketByValues.QUARTER:
        current = current.add(1, 'quarter').startOf('quarter')
        break
      case BucketByValues.YEAR:
        current = current.add(1, 'year').startOf('year')
        break
      default:
        break
    }
  }

  return intervals
}

export const bucketChartData = (
  chartItems: ChartItem[],
  bucketByValue: BucketByValues,
  dataField: AnyBaserowField,
  groupByField: AnyBaserowField,
  aggregateBy: BarChartAggregateType,
  aggregateField: AnyBaserowField,
  yAxisType: 'recordCount' | 'fieldSummary',
  showAllIntervals: boolean,
  sortBy: BarChartSort,
  sortOrder: BarChartOrder,
): ChartItem[] => {
  const bucketedValues: ChartItem[] = []
  const chartItemsDates: Dayjs[] = []
  for (const bar of chartItems) {
    // Exit early for the Empty Bar
    // Empty Bar will already have correct count and be filtered out if includeEmptyResults is false
    if (bar?.name === 'Empty') {
      const matchedEmpty = bucketedValues?.find(
        (barOption) => barOption?.name === 'Empty',
      )
      if (!matchedEmpty) {
        bucketedValues.push(bar)
      }
      continue
    }

    // Convert to DayJS
    const dateValue = convertStringToDayJsWithFieldFormat(bar?.name, dataField)

    // Check for valid date
    if (dateValue.isValid()) {
      // Get Bar Bucket String
      chartItemsDates.push(dateValue)
      const dateOption = getBucketByString(dateValue, bucketByValue, dataField)
      // Check for matching
      const matchingBar = bucketedValues?.find(
        (barOption) => barOption?.name === dateOption,
      )

      // Push Bar or Update count
      if (!matchingBar) {
        // Push cleaned val without grouped

        const initialBar = {
          name: dateOption,
          color: bar.color,
          hex: bar.hex,
          value: bar.value,
          records: bar.records,
          values: bar.values,
        }

        bucketedValues.push(initialBar)
      } else {
        if (aggregateBy === 'count' || aggregateBy === 'sum') {
          matchingBar.value += bar.value
        } else if (aggregateBy === 'min') {
          matchingBar.value = Math.min(matchingBar.value, bar.value)
        } else if (aggregateBy === 'max') {
          matchingBar.value = Math.max(matchingBar.value, bar.value)
        } else if (aggregateBy === 'average') {
          //do nothing, will be calculated later
        } else if (aggregateBy === 'median') {
          //do nothing, will be calculated later
        } else if (aggregateBy === 'distinct') {
          //do nothing, will be calculated later
        }
        matchingBar.value = clampValue(matchingBar.value, dataField)
        //combine all values and records
        matchingBar.values = [
          ...(matchingBar.values as number[]),
          ...(bar.values as number[]),
        ]

        matchingBar.records = [...matchingBar.records, ...bar.records]
      }
    }
  }

  if (aggregateBy === 'average') {
    for (const bar of bucketedValues) {
      if (bar.name === 'Empty') continue
      const values = bar.values as number[]
      bar.value = values.length > 0 ? sum(values) / values.length : 0
      bar.value = clampValue(bar.value, dataField)
    }
  }
  if (aggregateBy === 'median') {
    for (const bar of bucketedValues) {
      if (bar.name === 'Empty') continue
      const values = bar.values as number[]
      bar.value = values.length > 0 ? median(values) : 0
      bar.value = clampValue(bar.value, dataField)
    }
  }
  if (aggregateBy === 'distinct') {
    for (const bar of bucketedValues) {
      if (bar.name === 'Empty') continue
      if (yAxisType === 'recordCount') {
        bar.value = 1
        continue
      }
      const values = bar.values as number[]
      bar.value = values.length > 0 ? new Set(values).size : 0
      bar.value = clampValue(bar.value, dataField)
    }
  }
  // If groupBy, loop through bucketedValues and apply custom key
  if (groupByField) {
    for (const bucketValue of bucketedValues) {
      const recordValues = []
      //init value to 0
      bucketValue.value = 0
      for (const record of bucketValue.records) {
        const displayValue = getFieldDisplayValues(
          record?.getCellValue(groupByField?.name),
          groupByField,
        )[0]
        //if no display value, means it belongs to empty
        recordValues.push({
          displayValue: displayValue ? displayValue : 'Empty',
          value: getValueAsNumber(record?.getCellValue(aggregateField?.name)),
        })
      }
      const groupedData: { [key: string]: number[] } = recordValues.reduce(
        (acc, { displayValue, value }) => {
          if (!acc[displayValue]) {
            acc[displayValue] = []
          }
          acc[displayValue].push(value)
          return acc
        },
        {},
      )
      if (aggregateBy === 'count') {
        for (const [displayValue, values] of Object.entries(groupedData)) {
          bucketValue[displayValue] = values.length
          bucketValue.value += values.length
        }
      } else if (aggregateBy === 'distinct') {
        if (yAxisType === 'recordCount') {
          for (const displayValue of Object.keys(groupedData)) {
            bucketValue[displayValue] = 1
            bucketValue.value += 1
          }
        } else {
          for (const [displayValue, values] of Object.entries(groupedData)) {
            bucketValue[displayValue] = new Set(values).size
            bucketValue.value += new Set(values).size
          }
        }
      } else if (aggregateBy === 'sum') {
        for (const [displayValue, values] of Object.entries(groupedData)) {
          bucketValue[displayValue] = clampValue(sum(values), dataField)
          bucketValue.value += clampValue(sum(values), dataField)
        }
      } else if (aggregateBy === 'average') {
        for (const [displayValue, values] of Object.entries(groupedData)) {
          if (values.length === 0) continue
          bucketValue[displayValue] = clampValue(
            sum(values) / values.length,
            dataField,
          )
          bucketValue.value += clampValue(
            sum(values) / values.length,
            dataField,
          )
        }
      } else if (aggregateBy === 'min') {
        for (const [displayValue, values] of Object.entries(groupedData)) {
          const minValue = values.reduce(
            (min, val) => Math.min(min, val),
            values[0],
          )
          bucketValue[displayValue] = minValue
          bucketValue.value += minValue
        }
      } else if (aggregateBy === 'max') {
        for (const [displayValue, values] of Object.entries(groupedData)) {
          const maxValue = values.reduce(
            (max, val) => Math.max(max, val),
            values[0],
          )
          bucketValue[displayValue] = maxValue
          bucketValue.value += maxValue
        }
      } else if (aggregateBy === 'median') {
        for (const [displayValue, values] of Object.entries(groupedData)) {
          bucketValue[displayValue] = median(values)
          bucketValue.value += median(values)
        }
      }
    }
  }
  // Check and populate empty intervals
  if (
    sortBy === 'name' &&
    (sortOrder === 'asc' || sortOrder === 'desc') &&
    showAllIntervals
  ) {
    const results = getIntervalsExcludingChartItems(
      chartItemsDates,
      bucketByValue,
      dataField,
    )
    for (let i = 0; i < results.length; i++) {
      const initialBar = {
        name: results[i],
        color: 'gray',
        hex: getHexForColor(CHART_EMPTY_COLOR),
        value: 0,
        records: [],
        values: [],
      }
      bucketedValues.push(initialBar)
    }
  }
  return bucketedValues
}
