import { clamp, median, sum, isNil } from 'ramda'

export const MAX_DECIMAL_COUNT = 2

export const getSummaryTypeValueSum = (values: number[]): number => {
  const availableValues = values?.filter((value) => !isNil(value))

  // Exit Early
  if (!availableValues?.length) {
    return 0
  }
  return Number(
    sum(availableValues).toFixed(
      clamp(0, MAX_DECIMAL_COUNT, MAX_DECIMAL_COUNT),
    ),
  )
}

export const getSummaryTypeValueAverage = (values: number[]): number => {
  const availableValues = values?.filter((value) => !isNil(value))

  // Exit Early
  if (!availableValues?.length) {
    return 0
  }

  return Number(
    (sum(availableValues) / availableValues?.length).toFixed(
      clamp(0, MAX_DECIMAL_COUNT, MAX_DECIMAL_COUNT),
    ),
  )
}

export const getSummaryTypeValueMin = (values: number[]): number => {
  const availableValues = values
    ?.filter((value) => !isNil(value))
    ?.sort((a, b) => a - b)

  // Exit Early
  if (!availableValues?.length) {
    return 0
  }

  return Number(
    Number(availableValues[0] ?? 0).toFixed(
      clamp(0, MAX_DECIMAL_COUNT, MAX_DECIMAL_COUNT),
    ),
  )
}

export const getSummaryTypeValueMax = (values: number[]): number => {
  const availableValues = values
    ?.filter((value) => !isNil(value))
    ?.sort((a, b) => b - a)

  // Exit Early
  if (!availableValues?.length) {
    return 0
  }

  return Number(
    Number(availableValues[0] ?? 0).toFixed(
      clamp(0, MAX_DECIMAL_COUNT, MAX_DECIMAL_COUNT),
    ),
  )
}

export const getSummaryTypeValueMedian = (values: number[]): number => {
  const availableValues = values
    ?.filter((value) => !isNil(value))
    ?.sort((a, b) => a - b)

  // Exit Early
  if (!availableValues?.length) {
    return 0
  }
  return Number(
    median(availableValues).toFixed(
      clamp(0, MAX_DECIMAL_COUNT, MAX_DECIMAL_COUNT),
    ),
  )
}

export const numberFormatter = (value: number): number => {
  return Number(value.toFixed(clamp(0, MAX_DECIMAL_COUNT, MAX_DECIMAL_COUNT)))
}
