import { useEffect } from 'react'

import useHubDashStore from 'src/lib/stores/hubDashStore'

interface UseBaserowTokenStorage {
  token: string | null
  isValid: boolean
  setToken: (token: string) => void
  removeToken: () => void
}

export const useBaserowTokenStorage = (): UseBaserowTokenStorage => {
  const validateToken = (token: string): boolean => {
    try {
      const tokenData = JSON.parse(atob(token.split('.')[1]))
      const expirationTime = tokenData.exp * 1000
      return Date.now() < expirationTime
    } catch (error) {
      return false
    }
  }

  const getStoredToken = (): string | null => {
    return localStorage.getItem('br-token')
  }

  const setToken = (token: string) => {
    localStorage.setItem('br-token', token)
    useHubDashStore.setState({ token })
  }

  const removeToken = () => {
    localStorage.removeItem('br-token')
    useHubDashStore.setState({ token: null })
  }

  const storedToken = getStoredToken()
  const isValid = storedToken ? validateToken(storedToken) : false

  useEffect(() => {
    if (storedToken && !isValid) {
      removeToken()
    } else if (storedToken && isValid) {
      useHubDashStore.setState({ token: storedToken })
    }
  }, [])

  return {
    token: storedToken,
    isValid,
    setToken,
    removeToken,
  }
}
