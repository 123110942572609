import { useState, type Dispatch, type SetStateAction } from 'react'

import {
  AcademicCapIcon,
  BookOpenIcon,
  CubeTransparentIcon,
  MapIcon,
  TrashIcon,
  LinkIcon,
} from '@heroicons/react/24/outline'

import { Accordion } from 'src/components/Library/Accordion'
import Button from 'src/components/Library/Button/Button'
import IconButton from 'src/components/Library/IconButton/IconButton'

import {
  LinkedResourceType,
  type HubDashLinkedContent,
} from '../../HubDashLayoutListCell'

import LinkLearningContentDialog, {
  getContentNiceName,
  type LinkedLearnerTaskDetails,
} from './LinkLearningContentDialog'

interface LinkedLearningContentProps {
  linkedLearningContent: HubDashLinkedContent[]
  setLinkedLearningContent: Dispatch<SetStateAction<HubDashLinkedContent[]>>
}

export const LinkedResourceIcon = ({ type }: { type: LinkedResourceType }) => {
  switch (type) {
    case LinkedResourceType.PROCESS_MAP:
      return <MapIcon className="h-5 w-5" />
    case LinkedResourceType.KNOWLEDGE_BASE:
      return <BookOpenIcon className="h-5 w-5" />
    case LinkedResourceType.LEARNER_COURSE:
      return <AcademicCapIcon className="h-5 w-5" />
    case LinkedResourceType.HOME_LINK:
      return <LinkIcon className="h-5 w-5" />
    default:
      return <CubeTransparentIcon className="h-5 w-5" />
  }
}

const LinkedItem = ({
  title,
  type,
  linkedLessons = [],
  removeFromList,
}: {
  title: string
  type: LinkedResourceType
  linkedLessons?: LinkedLearnerTaskDetails[]
  removeFromList: () => void
}) => {
  return (
    <div>
      <div
        className={`flex w-full flex-grow grow items-center rounded border p-1 ${linkedLessons?.length > 0 ? 'rounded-b-none border-b-0' : ''}`}
      >
        <div className="flex min-w-[0] grow items-center gap-2">
          <div className="rounded-full bg-indigo-100 p-1 text-indigo-600">
            <LinkedResourceIcon type={type} />
          </div>
          <p className="truncate">{title}</p>
        </div>
        <IconButton className="shrink-0" onClick={() => removeFromList()}>
          <TrashIcon className="h-4 w-4 text-red-500" />
        </IconButton>
      </div>

      {linkedLessons?.length > 0 && (
        <div className="w-full rounded-b border border-t-0 bg-gray-50">
          {linkedLessons?.map((lesson) => (
            <p className="border-t px-4 py-2" key={lesson?.taskId}>
              {lesson?.taskName}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

const LinkLearningContent = ({
  linkedLearningContent,
  setLinkedLearningContent,
}: LinkedLearningContentProps) => {
  // Selector Dialog
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [contentType, setContentType] = useState<LinkedResourceType>(null)

  const filterLinkedResourcesByType = (
    filterByResourceType: LinkedResourceType,
    linkedLearningContent: HubDashLinkedContent[],
  ): HubDashLinkedContent[] => {
    return linkedLearningContent?.filter(
      (linkedItem) =>
        (linkedItem?.resourceType as LinkedResourceType) ===
        filterByResourceType,
    )
  }

  // Get defaults for each type
  const existingLinkedProcessMaps = filterLinkedResourcesByType(
    LinkedResourceType.PROCESS_MAP,
    linkedLearningContent,
  )

  const existingLinkedKnowledgeBaseItems = filterLinkedResourcesByType(
    LinkedResourceType.KNOWLEDGE_BASE,
    linkedLearningContent,
  )

  const existingLinkedLearnerItems = filterLinkedResourcesByType(
    LinkedResourceType.LEARNER_COURSE,
    linkedLearningContent,
  )

  const existingLinkedHomeLinkItems = filterLinkedResourcesByType(
    LinkedResourceType.HOME_LINK,
    linkedLearningContent,
  )

  // Existing Linked content
  const [linkedProcessMaps, setLinkedProcessMaps] = useState<
    HubDashLinkedContent[]
  >(existingLinkedProcessMaps)

  const [linkedKnowledgeBaseItems, setLinkedKnowledgeBaseItems] = useState<
    HubDashLinkedContent[]
  >(existingLinkedKnowledgeBaseItems)

  const [linkedLearnerItems, setLinkedLearnerItems] = useState<
    HubDashLinkedContent[]
  >(existingLinkedLearnerItems)

  const [linkedHomeLinkItems, setLinkedHomeLinkItems] = useState<
    HubDashLinkedContent[]
  >(existingLinkedHomeLinkItems)

  const handleClickOpen = (newContentType: LinkedResourceType) => {
    setContentType(newContentType)
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
    setContentType(null)
  }

  const handleSave = (
    selectedItems: HubDashLinkedContent[],
    contentType: LinkedResourceType,
  ) => {
    // Filter out what was existing on the type we are modifying
    const existingContentWithoutNew = linkedLearningContent?.filter(
      (item) => item?.resourceType !== contentType,
    )

    // Post in new content to save to DB
    setLinkedLearningContent([...existingContentWithoutNew, ...selectedItems])

    contentType === LinkedResourceType.PROCESS_MAP &&
      setLinkedProcessMaps(selectedItems)

    contentType === LinkedResourceType.KNOWLEDGE_BASE &&
      setLinkedKnowledgeBaseItems(selectedItems)

    contentType === LinkedResourceType.LEARNER_COURSE &&
      setLinkedLearnerItems(selectedItems)

    contentType === LinkedResourceType.HOME_LINK &&
      setLinkedHomeLinkItems(selectedItems)

    setOpenDialog(false)
  }

  const removeResourceFromList = (
    removeId: number,
    list: HubDashLinkedContent[],
    contentType: LinkedResourceType,
  ) => {
    // Filter out removed Item
    const filteredList = list.filter((item) => item?.resourceId !== removeId)

    // Push state up to save
    handleSave(filteredList, contentType)
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      <Accordion
        title={`${getContentNiceName(LinkedResourceType.PROCESS_MAP)}s`}
        expanded={linkedProcessMaps?.length > 0}
      >
        <div className="flex flex-col gap-2">
          {linkedProcessMaps?.map((linkedItem) => (
            <LinkedItem
              key={linkedItem?.resourceId}
              title={linkedItem?.resourceName}
              type={linkedItem?.resourceType as LinkedResourceType}
              removeFromList={() =>
                removeResourceFromList(
                  linkedItem?.resourceId,
                  linkedProcessMaps,
                  LinkedResourceType.PROCESS_MAP,
                )
              }
            />
          ))}
          {linkedProcessMaps?.length === 0 && (
            <p className="w-full py-2 text-center text-gray-400">
              {`No Linked ${getContentNiceName(LinkedResourceType.PROCESS_MAP)}s`}
            </p>
          )}
          <Button
            variant={'outlined'}
            onClick={() => handleClickOpen(LinkedResourceType.PROCESS_MAP)}
          >
            {`Manage ${getContentNiceName(LinkedResourceType.PROCESS_MAP)}s`}
          </Button>
        </div>
      </Accordion>
      <Accordion
        title={`${getContentNiceName(LinkedResourceType.KNOWLEDGE_BASE)}s`}
        expanded={linkedKnowledgeBaseItems?.length > 0}
      >
        <div className="flex flex-col gap-2">
          {linkedKnowledgeBaseItems?.map((linkedItem) => (
            <LinkedItem
              key={linkedItem?.resourceId}
              title={linkedItem?.resourceName}
              type={linkedItem?.resourceType as LinkedResourceType}
              removeFromList={() =>
                removeResourceFromList(
                  linkedItem?.resourceId,
                  linkedKnowledgeBaseItems,
                  LinkedResourceType.KNOWLEDGE_BASE,
                )
              }
            />
          ))}
          {linkedKnowledgeBaseItems?.length === 0 && (
            <p className="w-full py-2 text-center text-gray-400">
              {`No Linked ${getContentNiceName(LinkedResourceType.KNOWLEDGE_BASE)}s`}
            </p>
          )}
          <Button
            variant={'outlined'}
            onClick={() => handleClickOpen(LinkedResourceType.KNOWLEDGE_BASE)}
          >
            {`Manage ${getContentNiceName(LinkedResourceType.KNOWLEDGE_BASE)}s`}
          </Button>
        </div>
      </Accordion>
      <Accordion
        title={`${getContentNiceName(LinkedResourceType.LEARNER_COURSE)}s`}
        expanded={linkedLearnerItems?.length > 0}
      >
        <div className="flex flex-col gap-2">
          {linkedLearnerItems?.map((linkedItem) => (
            <LinkedItem
              key={linkedItem?.resourceId}
              title={linkedItem?.resourceName}
              type={linkedItem?.resourceType as LinkedResourceType}
              linkedLessons={
                (linkedItem?.linkMetaData as LinkedLearnerTaskDetails[]) ?? []
              }
              removeFromList={() =>
                removeResourceFromList(
                  linkedItem?.resourceId,
                  linkedLearnerItems,
                  LinkedResourceType.LEARNER_COURSE,
                )
              }
            />
          ))}
          {linkedLearnerItems?.length === 0 && (
            <p className="w-full py-2 text-center text-gray-400">
              {`No Linked ${getContentNiceName(LinkedResourceType.LEARNER_COURSE)}s`}
            </p>
          )}
          <Button
            variant={'outlined'}
            onClick={() => handleClickOpen(LinkedResourceType.LEARNER_COURSE)}
          >
            {`Manage ${getContentNiceName(LinkedResourceType.LEARNER_COURSE)}s`}
          </Button>
        </div>
      </Accordion>
      <Accordion
        title={`${getContentNiceName(LinkedResourceType.HOME_LINK)}s`}
        expanded={linkedHomeLinkItems?.length > 0}
      >
        <div className="flex flex-col gap-2">
          {linkedHomeLinkItems?.map((linkedItem) => (
            <LinkedItem
              key={linkedItem?.resourceId}
              title={linkedItem?.resourceName}
              type={linkedItem?.resourceType as LinkedResourceType}
              removeFromList={() =>
                removeResourceFromList(
                  linkedItem?.resourceId,
                  linkedHomeLinkItems,
                  LinkedResourceType.HOME_LINK,
                )
              }
            />
          ))}
          {linkedHomeLinkItems?.length === 0 && (
            <p className="w-full py-2 text-center text-gray-400">
              {`No Linked ${getContentNiceName(LinkedResourceType.HOME_LINK)}s`}
            </p>
          )}
          <Button
            variant={'outlined'}
            onClick={() => handleClickOpen(LinkedResourceType.HOME_LINK)}
          >
            {`Manage ${getContentNiceName(LinkedResourceType.HOME_LINK)}s`}
          </Button>
        </div>
      </Accordion>
      <LinkLearningContentDialog
        isOpen={openDialog}
        contentType={contentType}
        linkedProcessMaps={linkedProcessMaps}
        linkedKnowledgeBaseItems={linkedKnowledgeBaseItems}
        linkedLearnerItems={linkedLearnerItems}
        linkedHomeLinkItems={linkedHomeLinkItems}
        handleClose={handleClose}
        handleSave={handleSave}
      />
    </div>
  )
}

export default LinkLearningContent
