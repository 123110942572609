import type { FC } from 'react'

import { trimToCharLength } from 'src/Util'

import { BaserowFieldType } from '../lib/enums'
import numberInputFormatter from '../lib/numberInputFormatter'
import type { AnyBaserowField } from '../lib/types'

import type { ChartItem, GroupOption } from './ChartFunctions/getChartData'

interface ChartLegendProps {
  data?: ChartItem[]
  isHorizontal?: boolean
  isGrouped?: boolean
  groupOptions?: GroupOption[]
  textColor?: string
  matchingField?: AnyBaserowField
}

const LegendItem = ({
  index,
  hex,
  name,
  color,
  matchingField,
}: {
  index: number
  hex: string
  name: string
  color: string
  matchingField: AnyBaserowField
}) => {
  const formatNumberField = (value: string) => {
    if (matchingField?.type === BaserowFieldType.NUMBER) {
      if (value === 'Empty') return trimToCharLength(value, 25)
      const decimalPlaces = matchingField?.number_decimal_places ?? 0
      const spaceSeparator = matchingField?.number_separator
      const prefix = matchingField?.number_prefix
      const suffix = matchingField?.number_suffix
      return numberInputFormatter(
        spaceSeparator,
        value,
        prefix,
        suffix,
        decimalPlaces,
      )
    } else {
      return trimToCharLength(value, 25)
    }
  }
  return (
    <div
      key={`legend-item-${index}`}
      className="flex max-w-[250px] items-center gap-1"
    >
      <div
        className="h-4 w-4 min-w-4 flex-shrink-0 rounded-full"
        style={{ backgroundColor: hex }}
      ></div>
      <div className="flex-shrink-1">
        <p className="truncate text-sm" style={{ color }}>
          {formatNumberField(name)}
        </p>
      </div>
    </div>
  )
}

const ChartLegend: FC<ChartLegendProps> = ({
  data = [],
  isHorizontal = false,
  isGrouped = false,
  groupOptions = [],
  textColor = '#737373',
  matchingField,
}) => {
  return (
    <div
      className={`flex h-full gap-1 overflow-scroll ${isHorizontal ? 'flex-row flex-wrap justify-start' : 'h-full flex-col'} `}
    >
      {isGrouped &&
        groupOptions.map((entry, index) => (
          <LegendItem
            key={index}
            index={index}
            hex={entry?.hex}
            name={entry?.name ?? 'Unnamed'}
            color={textColor}
            matchingField={matchingField}
          />
        ))}
      {!isGrouped &&
        data.map((entry, index) => (
          <LegendItem
            key={index}
            index={index}
            hex={entry?.hex}
            name={entry?.name ?? 'Unnamed'}
            color={textColor}
            matchingField={matchingField}
          />
        ))}
    </div>
  )
}

export default ChartLegend
