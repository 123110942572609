import { useEffect } from 'react'

import HubDash from 'src/components/HubDash/HubDash'
import Spinner from 'src/components/Library/Loading'
import { useBaserowTokenStorage } from 'src/lib/hooks/useBaserowTokenStorage'

export const QUERY = gql`
  query LoginToBaserowQuery {
    loginToBaserow {
      jwt
    }
  }
`

export const Loading = () => <Spinner />

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }) => {
  return <div style={{ color: 'red' }}>Error: {error?.message}</div>
}

export const Success = ({ loginToBaserow }) => {
  const { setToken } = useBaserowTokenStorage()

  useEffect(() => {
    setToken(loginToBaserow.jwt)
  }, [loginToBaserow.jwt])

  return <HubDash />
}
