import {
  type Dispatch,
  type FC,
  type SetStateAction,
  type ChangeEvent,
} from 'react'

import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
} from '@mui/material'
import { IconoirProvider } from 'iconoir-react'

import Switch from 'src/components/Library/Switch/Switch'

import { BaserowFieldType } from '../../lib/enums'
import type {
  AnyBaserowField,
  BaserowReference,
  HubDashChartSettings,
} from '../../lib/types'
import { PieChartSettings } from '../../lib/types'
import { getIconForField } from '../../RecordExpand/RecordExpand'
import type { CardFormErrors } from '../CardSettingsDrawer'

interface PieChartDataSettingsProps {
  fieldsList: AnyBaserowField[]
  chartSettings: HubDashChartSettings
  setChartSettings: Dispatch<SetStateAction<HubDashChartSettings>>
  chartCategory: AnyBaserowField
  setChartCategory: Dispatch<SetStateAction<AnyBaserowField>>
  loadingViews: boolean
  selectedTable: BaserowReference
  formErrors: CardFormErrors
  setFormErrors: Dispatch<SetStateAction<CardFormErrors>>
}
const PieChartDataSettings: FC<PieChartDataSettingsProps> = ({
  fieldsList,
  chartSettings,
  setChartSettings,
  chartCategory,
  setChartCategory,
  loadingViews,
  selectedTable,
  formErrors,
  setFormErrors,
}) => {
  const pieChartSettings = chartSettings?.pieChart as PieChartSettings

  const arrayType = [
    BaserowFieldType.MULTIPLE_SELECT,
    BaserowFieldType.FILE,
    BaserowFieldType.LINK_ROW,
    BaserowFieldType.LOOKUP,
    BaserowFieldType.MULTIPLE_COLLABORATORS,
  ]
  const isArrayTypeCategory = arrayType.includes(chartCategory?.type)

  return (
    <>
      <FormControl fullWidth error={selectedTable && !!formErrors.category}>
        <Autocomplete
          options={fieldsList}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Category"
              error={selectedTable && !!formErrors.category}
            />
          )}
          value={chartCategory}
          onChange={(_, newValue) => {
            if (newValue) {
              setFormErrors({
                ...formErrors,
                category: '',
              })
            }
            setChartSettings({
              ...chartSettings,
              category: newValue,
            })
            setChartCategory(newValue)
          }}
          loading={loadingViews}
          disabled={!selectedTable}
          renderOption={(props, option) => {
            const { key, ...rest } = props
            return (
              <li key={key} {...rest}>
                <div className="flex items-center justify-between gap-4">
                  <IconoirProvider
                    iconProps={{
                      color: '#AAAAAA',
                      strokeWidth: 2,
                      width: '1em',
                      height: '1em',
                    }}
                  >
                    {getIconForField({ type: option?.type })}
                  </IconoirProvider>

                  <p>{option?.name}</p>
                </div>
              </li>
            )
          }}
        />
        <FormHelperText>{selectedTable && formErrors.category}</FormHelperText>
      </FormControl>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            className="m-0 flex w-full justify-between"
            value="start"
            control={
              <Switch
                color="primary"
                checked={
                  pieChartSettings?.viewAxis?.includeEmptyRecords ?? true
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setChartSettings({
                    ...chartSettings,
                    pieChart: {
                      ...pieChartSettings,
                      viewAxis: {
                        ...pieChartSettings?.viewAxis,
                        includeEmptyRecords: event.target.checked,
                      },
                    },
                  })
                }}
              />
            }
            label="Include empty records"
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>
      {isArrayTypeCategory && (
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              className="m-0 flex w-full justify-between"
              value="start"
              control={
                <Switch
                  color="primary"
                  checked={
                    pieChartSettings?.viewAxis?.splitMultipleValues ?? false
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setChartSettings({
                      ...chartSettings,
                      pieChart: {
                        ...pieChartSettings,
                        viewAxis: {
                          ...pieChartSettings?.viewAxis,
                          splitMultipleValues: event.target.checked,
                        },
                      },
                    })
                  }}
                />
              }
              label="Split multiple values"
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
      )}
    </>
  )
}

export default PieChartDataSettings
