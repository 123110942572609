import {
  useEffect,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import { XMarkIcon } from '@heroicons/react/20/solid'
import { Drawer, Tab, Tabs, useMediaQuery } from '@mui/material'
import { FLAG_FEATURES } from 'api/src/common/enums'
import { motion } from 'framer-motion'

import InfoPanel from 'src/components/HubDash/CardExpand/InfoPanel/InfoPanel'
import type { CardSocket } from 'src/components/HubDash/lib/baserow/useBaserowSocket.tsx'
import { CardType } from 'src/components/HubDash/lib/enums'
import RecordExpand from 'src/components/HubDash/RecordExpand/RecordExpand'
import RecordGrid from 'src/components/HubDash/RecordGrid/RecordGrid'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import useLiveFeature from 'src/lib/hooks/LiveFeatureFlag/UseLiveFeature'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import type { HubDashCardType } from '../lib/types'

import LinkedLearningContentList from './LinkedLearningContentList'

interface CardExpandProps {
  cardExpandOpen: boolean
  setCardExpandOpen: Dispatch<SetStateAction<boolean>>
  cardSocket: CardSocket
  card: HubDashCardType
}

export const CardExpand: FC<CardExpandProps> = ({
  cardExpandOpen,
  setCardExpandOpen,
  cardSocket,
  card,
}) => {
  const filterColors = card?.cardSettings?.filters?.counter?.count === 'colors'
  const isGraph =
    card.type === CardType.CHART_BAR || card.type === CardType.CHART_PIE
  const [activeTab, setActiveTab] = useState(
    isGraph ? 'all' : filterColors ? 'action' : 'all',
  )
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [commentsOpen, setCommentsOpen] = useState(false)
  const [selectedBorderColor, setSelectedBorderColor] = useState('white')
  const [selectedBackgroundColor, setSelectedBackgroundColor] =
    useState('white')
  const [recordSearchVal, setRecordSearchVal] = useState('')
  const isSmallDisplay = useMediaQuery('(max-width:1220px)')

  const { trackEvent } = useAnalytics()

  const [linkedLearningContentEnabled] = useLiveFeature(
    FLAG_FEATURES.WORKFLOWS_LEARNING_CONTENT,
  )

  const handleClose = () => {
    setCardExpandOpen(false)
    setSelectedRecord(null)
    setActiveTab(isGraph ? 'all' : filterColors ? 'action' : 'all')
    setSelectedBorderColor('white')
    setSelectedBackgroundColor('white')
    trackEvent('HubDash', 'Close Card Expand Modal')
  }

  useEffect(() => {
    if (cardExpandOpen && selectedRecord) {
      for (const record of cardSocket.records) {
        if (record.id === selectedRecord?.id) {
          setSelectedRecord(record)
          break
        }
      }
    }
  }, [cardSocket.records])

  useEffect(() => {
    if (!selectedRecord) {
      setCommentsOpen(false)
    } else {
      setCommentsOpen(true)
    }
  }, [selectedRecord])

  if (!cardSocket?.table?.fields) {
    return null
  }

  return (
    <Drawer
      anchor={'right'}
      open={cardExpandOpen}
      onClose={() => handleClose()}
      PaperProps={{
        style: {
          width: isSmallDisplay ? '100%' : '80%',
          height: '100%',
        },
      }}
    >
      <div className="flex justify-between border-b border-gray-200 px-4 py-2">
        <div className="flex items-center gap-4">
          <div className="">
            <p className="text-3xl">{card.name}</p>
          </div>
          <div className="flex flex-col border-l border-gray-300 pl-4">
            <p className="text text-gray-500">{cardSocket.table.name}</p>
            <p className="text text-gray-500">{cardSocket.view.name}</p>
          </div>
        </div>
        <button
          onClick={() => setCardExpandOpen(false)}
          className="cursor-pointer rounded-lg hover:bg-gray-100"
        >
          <XMarkIcon className="h-10 w-10 text-gray-500" />
        </button>
      </div>
      <div
        className="flex h-full w-full flex-col overflow-clip"
        style={{ height: 'calc(100% - 65px)' }}
      >
        <div className="flex h-full w-full">
          {!selectedRecord && (
            <div className="flex w-full flex-col gap-4 overflow-hidden px-4 pt-3">
              {filterColors && (
                <div className="flex items-center justify-between gap-4">
                  <Tabs
                    value={activeTab}
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setActiveTab(String(newValue))
                        trackEvent('HubDash', 'Change Tabs Card Expand', {
                          newTab: String(newValue),
                        })
                      }
                    }}
                  >
                    <Tab
                      label={`Action Required - ${cardSocket.activeRecords.length}`}
                      value="action"
                    />
                    <Tab
                      label={`No Action Required - ${cardSocket.inactiveRecords.length}`}
                      value="noAction"
                    />
                    <Tab
                      label={`All - ${cardSocket.records.length}`}
                      value="all"
                    />
                  </Tabs>
                  <div className="ml-auto w-96">
                    <SearchField
                      value={recordSearchVal}
                      onChange={(e) => setRecordSearchVal(e)}
                      placeholder="Search"
                    />
                  </div>
                </div>
              )}
              {!filterColors && (
                <div className="flex items-center justify-between gap-4">
                  <Tabs
                    value={activeTab}
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setActiveTab(String(newValue))
                        trackEvent('HubDash', 'Change Tabs Card Expand', {
                          newTab: String(newValue),
                        })
                      }
                    }}
                  >
                    <Tab
                      label={`All - ${cardSocket.records.length}`}
                      value="all"
                    />
                  </Tabs>
                  <div className="ml-auto w-96">
                    <SearchField
                      value={recordSearchVal}
                      onChange={(e) => setRecordSearchVal(e)}
                      placeholder="Search"
                    />
                  </div>
                </div>
              )}

              {cardExpandOpen && (
                <motion.div
                  initial={{ x: 0 }}
                  whileInView={{ x: 0 }}
                  className="h-full"
                >
                  <RecordGrid
                    cardSocket={cardSocket}
                    card={card}
                    isColorFiltered={
                      activeTab === 'action' || activeTab === 'noAction'
                        ? filterColors
                        : false
                    }
                    isAction={activeTab === 'action' || activeTab === 'all'}
                    setSelectedRecord={setSelectedRecord}
                    selectedBorderColor={selectedBorderColor}
                    selectedBackgroundColor={selectedBackgroundColor}
                    recordSearchVal={recordSearchVal}
                  />
                </motion.div>
              )}
            </div>
          )}
          {selectedRecord && (
            <motion.div
              initial={{ x: 0 }}
              whileInView={{ x: 0 }}
              className="h-full w-full"
            >
              <RecordExpand
                cardSocket={cardSocket}
                selectedRecord={selectedRecord}
                clearWrappedRecord={() => setSelectedRecord(null)}
                setCommentsOpen={setCommentsOpen}
                commentsOpen={commentsOpen}
                card={card}
              />
            </motion.div>
          )}
          {!commentsOpen && (
            <div className="min-w-[400px] max-w-[400px] overflow-auto border-l border-gray-200">
              <InfoPanel
                card={card}
                selectedBorderColor={selectedBorderColor}
                setSelectedBorderColor={setSelectedBorderColor}
                selectedBackgroundColor={selectedBackgroundColor}
                setSelectedBackgroundColor={setSelectedBackgroundColor}
                view={cardSocket?.view}
                records={cardSocket?.records}
              />
              {linkedLearningContentEnabled && (
                <LinkedLearningContentList cardId={card?.id} />
              )}
            </div>
          )}
        </div>
      </div>
    </Drawer>
  )
}

export default CardExpand
