import { parseDurationValue } from 'api/src/common/baserowImport/baserow/modules/database/utils/duration'
import dayjs from 'dayjs'

import { isBucketField } from '../../CardSettingsDrawer/ChartSettings/BarChartDataSettings'
import {
  BaserowFieldType,
  BaserowFormulaType,
  BucketByValues,
  DateFormatType,
} from '../../lib/enums'
import type {
  AnyBaserowField,
  BarChartOrder,
  BarChartSort,
} from '../../lib/types'

import type { ChartItem } from './getChartData'

const compareNumber = (
  aValue: string | number,
  bValue: string | number,
): number => {
  const aNum = Number(aValue)
  const bNum = Number(bValue)
  if (isNaN(aNum) && isNaN(bNum)) return 0
  if (isNaN(aNum)) return 1
  if (isNaN(bNum)) return -1
  return aNum - bNum
}

const convertStringToDayJs = (
  dateString: string,
  dataField: AnyBaserowField,
  mayIncludeTime: boolean,
): dayjs.Dayjs => {
  const dateFormat = dataField?.date_format
  if (!dateFormat || !['EU', 'ISO', 'US'].includes(dateFormat)) {
    return dayjs(dateString).tz() // fallback to default parsing
  }
  const formatUsedToParse = DateFormatType[dateFormat]
  if (mayIncludeTime && dataField?.date_include_time) {
    const timeFormat =
      dataField?.date_time_format === '24' ? ' HH:mm' : ' hh:mma'
    return dayjs(dateString, `${formatUsedToParse}${timeFormat}`).tz()
  } else {
    return dayjs(dateString, `${formatUsedToParse}`).tz()
  }
}
const sortByHour = (
  aValue: string,
  bValue: string,
  dataField: AnyBaserowField,
) => {
  //aValue and bValue are time strings e.g "16/11/1996 13:00" or "16/11/1996 1:00 PM"
  const aDate = convertStringToDayJs(aValue, dataField, true)
  const bDate = convertStringToDayJs(bValue, dataField, true)
  if (aDate.isSame(bDate)) return 0
  return aDate.isAfter(bDate) ? 1 : -1
}
const sortByDay = (
  aValue: string,
  bValue: string,
  dataField: AnyBaserowField,
) => {
  //aValue and bValue are date strings e.g "01/01/2025" or iso or us format
  const aDate = convertStringToDayJs(aValue, dataField, false)
  const bDate = convertStringToDayJs(bValue, dataField, false)
  if (aDate.isSame(bDate)) return 0
  return aDate.isAfter(bDate) ? 1 : -1
}
const sortByWeek = (aValue: string, bValue: string) => {
  // Extract year from the end of the string
  const aYear = parseInt(aValue.split(', ')[1], 10)
  const bYear = parseInt(bValue.split(', ')[1], 10)
  // Extract the start date of the week
  const aDate = dayjs(aValue.split(' - ')[0], 'DD MMM').tz()
  const bDate = dayjs(bValue.split(' - ')[0], 'DD MMM').tz()
  // First, sort by year
  if (aYear !== bYear) {
    return aYear - bYear
  }
  // If years are the same, sort by week start date
  return aDate.diff(bDate)
}
const sortByMonthOfYear = (aValue: string, bValue: string) => {
  //aValue and bValue are month strings e.g "January"
  const aDate = dayjs(aValue, 'MMMM').tz()
  const bDate = dayjs(bValue, 'MMMM').tz()
  if (aDate.isSame(bDate)) return 0
  return aDate.isAfter(bDate) ? 1 : -1
}
const sortByQuarter = (aValue: string, bValue: string) => {
  //aValue and bValue are quarter and year string e.g "Q1, 2025"
  const aYear = parseInt(aValue.split(', ')[1], 10)
  const bYear = parseInt(bValue.split(', ')[1], 10)
  const aQuarter = aValue.split(', ')[0]
  const bQuarter = bValue.split(', ')[0]
  // First, sort by year
  if (aYear !== bYear) {
    return aYear - bYear
  }
  // If years are the same, sort by quarter
  if (aQuarter === bQuarter) return 0
  return aQuarter > bQuarter ? 1 : -1
}
const sortByYear = (aValue: string, bValue: string) => {
  //aValue and bValue are year strings e.g "2025"
  const aDate = dayjs(aValue, 'YYYY').tz()
  const bDate = dayjs(bValue, 'YYYY').tz()
  if (aDate.isSame(bDate)) return 0
  return aDate.isAfter(bDate) ? 1 : -1
}
const sortByHourOfDay = (
  aValue: string,
  bValue: string,
  dataField: AnyBaserowField,
) => {
  //aValue and bValue are hour strings e.g "01:00 pm" or "13:00"
  const timeFormat = dataField?.date_time_format === '24' ? 'HH:00' : 'hh:00 A'
  const aDate = dayjs(aValue, timeFormat).tz()
  const bDate = dayjs(bValue, timeFormat).tz()
  if (aDate.isSame(bDate)) return 0
  return aDate.isAfter(bDate) ? 1 : -1
}
const sortByDayOfWeek = (aValue: string, bValue: string) => {
  //aValue and bValue are day of week strings e.g "Monday"
  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]
  const aIndex = daysOfWeek.indexOf(aValue)
  const bIndex = daysOfWeek.indexOf(bValue)
  if (aIndex === bIndex) return 0
  return aIndex > bIndex ? 1 : -1
}

const sortByMonth = (aValue: string, bValue: string) => {
  //aValue and bValue are month of year strings e.g "January, 2025"
  //MMMM represents the full month name (e.g., "January"), and YYYY is the year.
  const aDate = dayjs(aValue, 'MMMM, YYYY', true).tz() // Strict parsing
  const bDate = dayjs(bValue, 'MMMM, YYYY', true).tz() // Strict parsing
  if (aDate.isSame(bDate)) return 0
  return aDate.isAfter(bDate) ? 1 : -1
}

const sortByQuarterOfYear = (aValue: string, bValue: string) => {
  //aValue and bValue are quarter strings e.g "Q1"
  const aDate = aValue.split('Q')[1]
  const bDate = bValue.split('Q')[1]
  if (aDate === bDate) return 0
  return aDate > bDate ? 1 : -1
}

const checkBucketByHourValid = (
  dataField: AnyBaserowField,
  bucketValuesBy: BucketByValues,
) =>
  !!dataField?.date_include_time ||
  !(bucketValuesBy === 'hour' || bucketValuesBy === 'hourOfDay')

const valueComparison = (
  aValue: string | number = '',
  bValue: string | number = '',
  field: AnyBaserowField,
  bucketValuesBy: BucketByValues | null,
): number => {
  const numberType =
    [
      BaserowFieldType.COUNT,
      BaserowFieldType.AUTONUMBER,
      BaserowFieldType.NUMBER,
      BaserowFieldType.RATING,
      BaserowFieldType.RATING,
    ].includes(field?.type) || field?.formula_type === BaserowFormulaType.NUMBER
  if (field?.type === BaserowFieldType.DURATION) {
    // Sort by duration number
    return parseDurationValue(aValue) - parseDurationValue(bValue)
  } else if (numberType) {
    //Sort by Number
    return compareNumber(aValue, bValue)
  } else if (
    isBucketField(field) &&
    bucketValuesBy &&
    checkBucketByHourValid(field, bucketValuesBy)
  ) {
    //Sort by bucket values date
    if (aValue === 'Empty') return 1 // Move "Empty" to the end
    if (bValue === 'Empty') return -1 // Move "Empty" to the end
    if (bucketValuesBy === BucketByValues.HOUR) {
      return sortByHour(aValue as string, bValue as string, field)
    } else if (bucketValuesBy === BucketByValues.DAY) {
      return sortByDay(aValue as string, bValue as string, field)
    } else if (bucketValuesBy === BucketByValues.WEEK) {
      return sortByWeek(aValue as string, bValue as string)
    } else if (bucketValuesBy === BucketByValues.MONTH) {
      return sortByMonth(aValue as string, bValue as string)
    } else if (bucketValuesBy === BucketByValues.QUARTER) {
      return sortByQuarter(aValue as string, bValue as string)
    } else if (bucketValuesBy === BucketByValues.YEAR) {
      return sortByYear(aValue as string, bValue as string)
    } else if (bucketValuesBy === BucketByValues.HOUR_OF_DAY) {
      return sortByHourOfDay(aValue as string, bValue as string, field)
    } else if (bucketValuesBy === BucketByValues.DAY_OF_WEEK) {
      return sortByDayOfWeek(aValue as string, bValue as string)
    } else if (bucketValuesBy === BucketByValues.MONTH_OF_YEAR) {
      return sortByMonthOfYear(aValue as string, bValue as string)
    } else if (bucketValuesBy === BucketByValues.QUARTER_OF_YEAR) {
      return sortByQuarterOfYear(aValue as string, bValue as string)
    }
  } else if (isBucketField(field)) {
    //Sort by date
    if (aValue === 'Empty') return 1 // Move "Empty" to the end
    if (bValue === 'Empty') return -1 // Move "Empty" to the end
    const aDate = convertStringToDayJs(aValue as string, field, true)
    const bDate = convertStringToDayJs(bValue as string, field, true)
    if (aDate.isSame(bDate)) return 0
    return aDate.isAfter(bDate) ? 1 : -1
  } else {
    //Sort by String
    return String(aValue).toLowerCase() > String(bValue).toLowerCase() ? 1 : -1
  }
}

export const sortChartData = (
  chartData: ChartItem[],
  sortBy: BarChartSort,
  sortOrder: BarChartOrder,
  matchingField: AnyBaserowField,
  groupByField: AnyBaserowField = null,
  bucketValuesBy: BucketByValues = null,
): ChartItem[] => {
  // Apply sorting
  // Exit early if possible
  if (!chartData?.length || !sortOrder || sortOrder === 'default') {
    return chartData
  }

  const compareField = sortBy === 'value' ? 'value' : 'name'

  const sortedData = [...chartData].sort((a, b) => {
    if (compareField === 'value') {
      return compareNumber(a?.[compareField], b?.[compareField])
    } else {
      return valueComparison(
        a?.[compareField],
        b?.[compareField],
        matchingField ?? groupByField,
        bucketValuesBy,
      )
    }
  })

  return sortOrder === 'desc' ? sortedData.reverse() : sortedData
}
