import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { captureException } from '@sentry/browser'

import { useParams } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import {
  getRecordById,
  getFieldOptionsByViewId,
  getViewsForTable,
  getBaseById,
} from 'src/components/HubDash/lib/baserow/baserowApi'

interface Props {
  token: string
}

interface Result {
  isLoading: boolean
  table: any // not typed yet
  view: any // not typed yet
  record: any // not typed yet
  setRecord: Dispatch<SetStateAction<any>>
  fromNotification?: boolean
  baseId: string
}

// Used for the full page spinner while the record is being fetched
export const FullPageLoader = () => (
  <Box
    sx={{
      position: 'absolute',
      width: 'calc(100vw - 215px)', // 100% of the viewport width minus the sidebar offset
      height: 'calc(100vh - 64px)', // 100% of the viewport height minus the header offset
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      zIndex: 10,
    }}
  >
    <CircularProgress />
  </Box>
)

/**
 * This hook is used to fetch record data from Baserow when a user clicks on a mention notification
 * @param token - The user's Baserow token
 */
export default function useFetchRecordFromNotification({
  token,
}: Props): Result {
  const { tableId, recordId, fromNotification, baseId } = useParams()
  const [record, setRecord] = useState<any>(undefined) // not typed yet
  const [table, setTable] = useState<any>(undefined) // not typed yet
  const [view, setView] = useState<any>(undefined) // not typed yet
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    try {
      const getBaserowData = async () => {
        setIsLoading(true)
        const base = await getBaseById({ token, applicationId: baseId })
        const workspaceId = base?.workspace?.id

        // Initial record load
        const recordByIdResult = await getRecordById({
          tableId: Number(tableId),
          recordId: Number(recordId),
          token,
          workspaceId,
        })
        const { record, table } = recordByIdResult ?? {}

        if (!record) {
          setIsLoading(false)
          toast.error(
            'Record not found. You may have been removed from this workspace.',
            { duration: 10_000 },
          )
          return
        }
        const tableViews = await getViewsForTable({ token, tableId })
        if (!record.workspaceId && workspaceId) record.workspaceId = workspaceId

        // We check if the table has a view named 'Comments' and if it does, we get the field options
        // for that view and add the viewOrder and viewHidden properties to the table fields. This is
        // a workaround for the time being while we solve field level permissions in Baserow. This method
        // allows clients to control which fields are visible by defining them in the 'Comments' view.
        const commentsView = tableViews?.find(
          (view) => view?.name?.toLowerCase() === 'comments',
        )
        if (commentsView?.id) {
          const { field_options } = await getFieldOptionsByViewId({
            token,
            viewId: commentsView.id,
          })
          table.fields = table.fields.map((field) => ({
            ...field,
            viewOrder: field_options[field.id].order,
            viewHidden: field_options[field.id].hidden,
          }))
          table.viewName = 'Comments'
        }

        setView(commentsView || tableViews[0])
        setRecord(record)
        setTable(table)
        setIsLoading(false)
      }
      if (fromNotification === 'true' && token) getBaserowData()
    } catch (e) {
      captureException(e, {
        extra: {
          message: 'Error while fetching Baserow data on Notification open',
        },
      })
    }
  }, [tableId, recordId, token, fromNotification])

  // The Intercom button gets in the way of the comments when
  // the drawer is open, so we move it to the left.
  useEffect(() => {
    if (recordId && fromNotification) {
      window.Intercom('update', { alignment: 'left' })
    } else {
      window.Intercom('update', { alignment: 'right' })
    }
  }, [recordId, fromNotification])

  return {
    isLoading,
    record,
    setRecord,
    table,
    fromNotification: fromNotification === 'true',
    baseId,
    view,
  }
}
