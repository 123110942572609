import { useEffect, useMemo, useState, type FC } from 'react'

import {
  CalculatorIcon,
  ChartPieIcon,
  LanguageIcon,
} from '@heroicons/react/24/outline'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import { captureEvent, captureException } from '@sentry/browser'
import { Responsive, WidthProvider } from 'react-grid-layout'
import {
  UpdateCardsInLayout,
  type UpdateCardsInLayoutVariables,
  type UpdateHubDashCard,
  type UpdateHubDashCardVariables,
} from 'types/graphql'

import { navigate, routes, useParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import CardLibrary from 'src/components/HubDash/CardLibrary/CardLibraryDrawer'
import CardSettingsDrawer from 'src/components/HubDash/CardSettingsDrawer/CardSettingsDrawer'
import HeadingCard from 'src/components/HubDash/HubDashCard/HeadingCard'
import HubDashLayoutListCell from 'src/components/HubDash/HubDashLayoutListCell'
import loadBaserowData from 'src/components/HubDash/lib/baserow/loadBaserowData'
import PageHeader from 'src/components/PageHeader/PageHeader'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import useLocalStorage from 'src/lib/hooks/UseLocalStorage'
import useHubDashStore from 'src/lib/stores/hubDashStore'
import { useAuth } from 'src/Providers'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'src/styles/hubdash.css'

import Empty from '../Empty/Empty'

import useFetchRecordFromNotification, {
  FullPageLoader,
} from './hooks/useFetchRecordFromNotification'
import HubDashCard from './HubDashCard/HubDashCard'
import { CardType } from './lib/enums'
import type { CardPositionWithId, HubDashCardType } from './lib/types'
import { UPDATE_CARDS_IN_LAYOUT, UPDATE_HUBDASH_CARD } from './queries'
import RecordExpandWrapper from './RecordExpand/RecordExpandWrapper'

const HubDash: FC = () => {
  const [layoutData, isUnlocked, token] = useHubDashStore((state) => [
    state.layoutData,
    state.isUnlocked,
    state.token,
  ])
  const [cardSettingsOpen, setCardSettingsOpen] = useState(false)
  const [cardLibraryOpen, setCardLibraryOpen] = useState(false)
  const [cardsData, setCardsData] = useState<any[]>([])

  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), [])

  const { layoutId } = useParams()
  const {
    isLoading,
    table,
    record,
    setRecord,
    fromNotification,
    baseId,
    view,
  } = useFetchRecordFromNotification({ token })

  // State to trigger resize of Counter Text when the grid gets modified
  const [triggerResize, setTriggerResize] = useState<number>(null)

  const [updateCard] = useMutation<
    UpdateHubDashCard,
    UpdateHubDashCardVariables
  >(UPDATE_HUBDASH_CARD, {
    onError: (err) => {
      captureException(err, {
        extra: { message: 'HubDash: Failed to update card with Self Heal.' },
      })
    },
  })

  // FIX: update this to not reload cards that haven't been modified
  useEffect(() => {
    const fetchAllCardsData = async () => {
      if (!layoutData?.cards) {
        return
      }

      const promises = layoutData.cards.map(async (config) => {
        if (config.source === 'baserow') {
          try {
            const data = await loadBaserowData({
              workspaceId: config?.cardData?.workspace?.id,
              baseId: config?.cardData?.base?.id,
              tableId: config?.cardData?.table?.id,
              viewId: config?.cardData?.view?.id,
              tableName: config?.cardData?.table?.name,
              viewName: config?.cardData?.view?.name,
              token: token,
              config: config,
            })

            const updateCardInput = {}

            const cardFilterId = config?.cardSettings?.filters?.user?.id
            const cardFilterName = config?.cardSettings?.filters?.user?.name

            // If the card Name exists but no Id exists
            // This happens when copying a layout between clients and changing the target Base
            // Try and match with what was found
            if (cardFilterName && !cardFilterId) {
              const matchingField = data?.table?.fields?.find(
                (field) => field?.name === cardFilterName,
              )

              if (matchingField) {
                const newCardSettings = {
                  ...config?.cardSettings,
                  filters: {
                    ...config?.cardSettings?.filters,
                    user: matchingField,
                  },
                }

                updateCardInput['cardSettings'] = newCardSettings
              }
            }

            /*

            FIXME: This is too aggressive and is causing issues with the chart settings, stripping category when it shouldn't be

            // If the card chartSetting category is different to the baserow field, update it
            const chartSettingsCategory =
              config?.cardSettings?.chartSettings?.category
            if (chartSettingsCategory) {
              const baserowCategory = data.table.fields.find(
                (field) => field.id === chartSettingsCategory.id,
              )
              if (!isEqual(chartSettingsCategory, baserowCategory)) {
                const newCardSettings = {
                  ...config?.cardSettings,
                  chartSettings: {
                    ...config?.cardSettings?.chartSettings,
                    category: data.table.fields.find(
                      (field) => field.id === chartSettingsCategory.id,
                    ),
                  },
                }
                data.config.cardSettings = newCardSettings
                updateCardInput['cardSettings'] = newCardSettings
              }
            }
              */

            const cardTableTarget = config?.cardData?.table?.id
            const cardViewTarget = config?.cardData?.view?.id

            // If the card has no tableId or no viewID
            // This happens when copying a layout between clients and changing the target Base
            // Try to populate with what was found "Magically"
            if (!cardTableTarget || !cardViewTarget) {
              const cardTableFound = data?.table?.id
              const cardViewFound = data?.view?.id

              // Only run mutation if we have a result
              if (cardTableFound || cardViewFound) {
                const cardData = config?.cardData

                const newCardData = {
                  base: { id: cardData?.base?.id, name: cardData?.base?.name },
                  view: {
                    id: cardViewFound ?? cardData?.view?.id,
                    name: cardData?.view?.name,
                  },
                  table: {
                    id: cardTableFound ?? cardData?.table?.id,
                    name: cardData?.table?.name,
                  },
                  workspace: {
                    id: cardData?.workspace?.id,
                    name: cardData?.workspace?.name,
                  },
                }

                updateCardInput['cardData'] = newCardData
              }
            }

            if (Object.keys(updateCardInput)?.length > 0) {
              updateCard({
                variables: {
                  id: config.id,
                  input: updateCardInput,
                  relationId: config?.relationId,
                },
              })
            }

            setCardsData((prevData) => {
              const newData = [...prevData]
              newData[layoutData.cards.indexOf(config)] = data
              return newData
            })
          } catch (err) {
            const error = new Error(
              err?.message || 'Unknown error loading Baserow data',
            )
            captureEvent({
              message: 'HubDash: Unknown error loading Baserow data',
              level: 'info',
              extra: { error },
            })
            setCardsData((prevData) => {
              const newData = [...prevData]
              newData[layoutData.cards.indexOf(config)] = {
                config,
                errors: [
                  {
                    entity: 'core',
                    message:
                      'An error occurred. Please notify Stafflink Support if this persists.',
                  },
                ],
              }
              return newData
            })
          }
        } else {
          setCardsData((prevData) => {
            const newData = [...prevData]
            newData[layoutData.cards.indexOf(config)] = config
            return newData
          })
        }
      })

      const results = await Promise.allSettled(promises)
      const errors = results.filter((result) => result.status === 'rejected')
      if (errors.length > 0) {
        const areAllResultsError = errors.length === results.length
        captureEvent({
          message: 'HubDash: Failed to load Baserow data',
          level: 'warning',
          extra: {
            errors: errors.map((error) => error.reason),
            areAllResultsError,
          },
        })
        if (areAllResultsError) {
          toast.error(
            'Failed to load Baserow data. Refresh the page to retry.',
            { duration: 5000 },
          )
        } else {
          toast.error('Some cards failed to load. Refresh the page to retry.', {
            duration: 5000,
          })
        }
      }
    }

    if (layoutData?.cards) {
      fetchAllCardsData()
    }
  }, [JSON.stringify(layoutData?.cards)])

  const { trackEvent } = useAnalytics()

  const [updateLayout] = useMutation<
    UpdateCardsInLayout,
    UpdateCardsInLayoutVariables
  >(UPDATE_CARDS_IN_LAYOUT, {
    onCompleted: () => {},
  })
  const handleLayoutChange = (newLayout: CardPositionWithId[]) => {
    // this triggers on page load so we need to check if the layout
    // has changed before we update the layout
    if (!newLayout || !layoutData?.hubDashCards) {
      return
    }

    let compareIssue: boolean = false

    const newLayoutLength = newLayout?.length
    const layoutDataLength = layoutData?.hubDashCards.length

    if (
      newLayoutLength === 0 ||
      layoutDataLength === 0 ||
      newLayoutLength !== layoutDataLength
    ) {
      compareIssue = true
    }

    if (!compareIssue) {
      const keys = ['h', 'w', 'x', 'y']

      const layoutIsTheSame = newLayout
        .map((newPosition) => {
          const oldLayout = layoutData.hubDashCards.find(
            (card) => card.id.toString() === newPosition.i,
          )

          if (!oldLayout?.position) {
            return false
          }

          const oldPosition = oldLayout.position

          const positionIsTheSame = keys.every(
            (key) => oldPosition[key] === newPosition[key],
          )

          return positionIsTheSame
        })
        .every((isSame: boolean) => isSame)

      if (layoutIsTheSame) {
        return
      }
    }

    if (layoutData?.id) {
      const updatedLayout = { layoutId: layoutData?.id, layoutData: newLayout }

      updateLayout({ variables: updatedLayout })
    }
  }

  const [settingsCard, setSettingsCard] = useState<HubDashCardType>(null)

  const speedActions = [
    {
      name: 'New Counter',
      icon: <CalculatorIcon className="text-purple h-8 w-8" />,
      onClick: () => {
        setCardSettingsOpen(true)
        setSettingsCard({ type: CardType.COUNTER } as HubDashCardType)
        trackEvent('HubDash', 'Click New Counter')
      },
    },
    {
      name: 'New Chart',
      icon: <ChartPieIcon className="text-purple h-8 w-8" />,
      onClick: () => {
        setCardSettingsOpen(true)
        setSettingsCard({ type: CardType.CHART_PIE } as HubDashCardType)
        trackEvent('HubDash', 'Click New Chart')
      },
    },
    {
      name: 'New Heading',
      icon: <LanguageIcon className="text-purple h-8 w-8" />,
      onClick: () => {
        setCardSettingsOpen(true)
        setSettingsCard({ type: CardType.HEADING } as HubDashCardType)
        trackEvent('HubDash', 'Click New Heading')
      },
    },
  ]

  const { currentUser } = useAuth()

  // Remember Hubdash Last Layout
  const [storedHubDashLayoutId, setStoredHubDashLayoutId] =
    useLocalStorage<string>(
      `memberId-${currentUser.membershipData?.id}-StoredHubDashLayoutId`,
      '',
    )
  useEffect(() => {
    if (storedHubDashLayoutId && !fromNotification) {
      navigate(
        routes.hubDashWithId({ layoutId: Number(storedHubDashLayoutId) }),
      )
    }
  }, [])
  useEffect(() => {
    if (storedHubDashLayoutId !== layoutId) {
      setStoredHubDashLayoutId(layoutId)
    }
  }, [layoutId])

  return (
    <>
      <PageHeader title={'HubDash'} parentDataTestId="hubdash-page-title">
        <HubDashLayoutListCell />
      </PageHeader>
      {isLoading && <FullPageLoader />}
      {layoutData?.cards?.length === 0 && (
        <div className="grid h-[calc(100vh-80px)] place-items-center">
          <Empty defaultIcon title="There are no cards in this layout." />
        </div>
      )}
      {layoutData?.cards?.length > 0 && (
        <ResponsiveGridLayout
          key={layoutData?.id.toString()}
          rowHeight={50}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 }}
          isDraggable={isUnlocked}
          isResizable={isUnlocked}
          onLayoutChange={(layout) => {
            handleLayoutChange(layout)
            setTriggerResize(new Date().getTime())
          }}
        >
          {layoutData?.cards.map((card) => {
            switch (card.type) {
              case 'heading':
                return (
                  <div
                    key={card.id.toString()}
                    data-grid={{
                      h: card.position.h,
                      w: card.position.w,
                      x: card.position.x,
                      y: card.position.y,
                    }}
                  >
                    <HeadingCard
                      card={card}
                      setSettingsCard={setSettingsCard}
                      setCardSettingsOpen={setCardSettingsOpen}
                      isPreview={false}
                    />
                  </div>
                )
              default:
                return (
                  <div
                    key={card.id.toString()}
                    data-grid={{
                      h: card.position.h,
                      w: card.position.w,
                      x: card.position.x,
                      y: card.position.y,
                      minH: 3,
                    }}
                  >
                    <HubDashCard
                      triggerResize={triggerResize}
                      card={card}
                      baserowData={cardsData.find(
                        (c) => c?.config?.id === card.id,
                      )}
                      setSettingsCard={setSettingsCard}
                      setCardSettingsOpen={setCardSettingsOpen}
                      isPreview={false}
                    />
                  </div>
                )
            }
          })}
        </ResponsiveGridLayout>
      )}
      {isUnlocked && (
        <SpeedDial
          ariaLabel="Create Element"
          sx={{
            position: 'fixed',
            top: 108,
            right: 48,
          }}
          icon={
            <SpeedDialIcon
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg': {
                  width: 24,
                  height: 24,
                },
              }}
              data-testid="hubdash-create-element"
            />
          }
          direction={'down'}
        >
          {speedActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onClick}
              sx={{ width: 60, height: 60 }}
              data-testid={`hubdash-create-element-${action.name}`}
            />
          ))}
        </SpeedDial>
      )}
      <CardSettingsDrawer
        cardSettingsOpen={cardSettingsOpen}
        setCardSettingsOpen={setCardSettingsOpen}
        settingsCard={settingsCard}
      />
      <CardLibrary
        cardLibraryOpen={cardLibraryOpen}
        setCardLibraryOpen={setCardLibraryOpen}
      />
      {fromNotification && record?.id && (
        <RecordExpandWrapper
          baseId={Number(baseId)}
          wrappedTableName={table?.name}
          wrappedTable={table}
          wrappedRecord={record}
          wrappedView={view}
          clearWrappedRecord={() => {
            setRecord(undefined)
            navigate(routes.hubDashWithId({ layoutId: Number(layoutId) }))
          }}
        />
      )}
    </>
  )
}

export default HubDash
