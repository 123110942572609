import { type Dispatch, type FC, type SetStateAction, useEffect } from 'react'

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { IconoirProvider } from 'iconoir-react'

import { BaserowFieldType, BaserowFormulaType } from '../lib/enums'
import type { AnyBaserowField, BaserowReference } from '../lib/types'
import { getIconForField } from '../RecordExpand/RecordExpand'

import { CardFormErrors } from './CardSettingsDrawer'

export enum SummaryType {
  EMPTY = 'empty',
  FILLED = 'filled',
  PERCENT_EMPTY = 'percent-empty',
  PERCENT_FILLED = 'percent-filled',
  EARLIEST_DATE = 'earliest-date',
  LATEST_DATE = 'latest-date',
  MEDIAN = 'median',
  MIN = 'min',
  MAX = 'max',
  SUM = 'sum',
  AVERAGE = 'average',
}

export const summaryNiceName = (type: SummaryType): string => {
  switch (type) {
    case SummaryType.EMPTY:
      return 'Empty'
    case SummaryType.FILLED:
      return 'Filled'
    case SummaryType.PERCENT_EMPTY:
      return 'Percent Empty'
    case SummaryType.PERCENT_FILLED:
      return 'Percent Filled'
    case SummaryType.EARLIEST_DATE:
      return 'Earliest Date'
    case SummaryType.LATEST_DATE:
      return 'Latest Date'
    case SummaryType.MIN:
      return 'Min'
    case SummaryType.MAX:
      return 'Max'
    case SummaryType.MEDIAN:
      return 'Median'
    case SummaryType.SUM:
      return 'Sum'
    case SummaryType.AVERAGE:
      return 'Average'
    default:
      return ''
  }
}

export const numberTypeFields = [
  BaserowFieldType.NUMBER,
  BaserowFieldType.AUTONUMBER,
  BaserowFieldType.COUNT,
  BaserowFieldType.DURATION,
  BaserowFieldType.RATING,
]

export const dateTypeFields = [
  BaserowFieldType.LAST_MODIFIED,
  BaserowFieldType.DATE,
  BaserowFieldType.CREATED_ON,
]

export const formulaNumberFields = [
  BaserowFormulaType.NUMBER,
  BaserowFormulaType.DURATION,
]

export const formulaDateFields = [
  BaserowFormulaType.DATE,
  BaserowFormulaType.DATE_INTERVAL,
]

interface FieldSummarySettingsProps {
  fieldsList: AnyBaserowField[]
  loadingViews: boolean
  selectedTable: any // not typed yet
  selectedView: any // not typed yet
  summaryField: BaserowReference
  setSummaryField: Dispatch<SetStateAction<BaserowReference>>
  summaryType: SummaryType
  setSummaryType: Dispatch<SetStateAction<SummaryType>>
  formErrors: CardFormErrors // not typed yet
  setFormErrors: Dispatch<SetStateAction<CardFormErrors>>
}

const FieldSummarySettings: FC<FieldSummarySettingsProps> = ({
  fieldsList,
  loadingViews,
  selectedTable,
  selectedView,
  summaryField,
  setSummaryField,
  summaryType,
  setSummaryType,
  formErrors,
  setFormErrors,
}) => {
  // Match the type with what was found in field list
  const matchedField =
    fieldsList?.find((field) => field?.id === summaryField?.id) ?? null
  const matchedFieldType = matchedField?.type ?? BaserowFieldType.TEXT

  const showNumberTypes = numberTypeFields.includes(matchedFieldType)
  const showDateTypes = dateTypeFields.includes(matchedFieldType)

  const showNumberTypesForFormulaResult =
    formulaNumberFields.includes(matchedField?.formula_type) ||
    formulaNumberFields.includes(matchedField?.array_formula_type)

  const showDateTypesForFormulaResult =
    formulaDateFields.includes(matchedField?.formula_type) ||
    formulaDateFields.includes(matchedField?.array_formula_type)

  const summaryOptions = [
    SummaryType.EMPTY,
    SummaryType.FILLED,
    SummaryType.PERCENT_EMPTY,
    SummaryType.PERCENT_FILLED,
  ]

  if (showDateTypes || showDateTypesForFormulaResult) {
    summaryOptions.push(SummaryType.EARLIEST_DATE)
    summaryOptions.push(SummaryType.LATEST_DATE)
  }

  if (showNumberTypes || showNumberTypesForFormulaResult) {
    summaryOptions.push(SummaryType.AVERAGE)
    summaryOptions.push(SummaryType.SUM)
    summaryOptions.push(SummaryType.MIN)
    summaryOptions.push(SummaryType.MAX)
    summaryOptions.push(SummaryType.MEDIAN)
  }

  useEffect(() => {
    //reset if table or view are removed
    if (!selectedTable || !selectedView) {
      setSummaryField(null)
      setSummaryType(SummaryType.EMPTY)
    }
  }, [selectedTable, selectedView])

  return (
    <div className="flex flex-col gap-4 pt-4">
      <div className="flex flex-col gap-4">
        <FormControl
          className="w-full"
          disabled={!selectedTable || !selectedView}
          error={selectedView && formErrors.recordFilterField ? true : false}
        >
          <Autocomplete
            options={fieldsList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Field"
                error={
                  selectedView && formErrors.recordFilterField ? true : false
                }
              />
            )}
            disableClearable
            isOptionEqualToValue={(option, value) => {
              return option?.id === value?.id
            }}
            value={summaryField}
            onChange={(_, newValue) => {
              if (newValue) {
                setFormErrors({ ...formErrors, recordFilterField: '' })
              }
              setSummaryField({
                id: newValue?.id,
                name: newValue?.name,
              })
              // Reset Val on Change
              setSummaryType(SummaryType.EMPTY)
            }}
            loading={loadingViews}
            disabled={!selectedTable || !selectedView}
            renderOption={(props, option) => {
              const { key, ...restProps } = props
              return (
                <li key={key} {...restProps}>
                  <div className="flex items-center justify-between gap-4">
                    <IconoirProvider
                      iconProps={{
                        color: '#AAAAAA',
                        strokeWidth: 2,
                        width: '1em',
                        height: '1em',
                      }}
                    >
                      {getIconForField({ type: option?.type })}
                    </IconoirProvider>

                    <p>{option?.name}</p>
                  </div>
                </li>
              )
            }}
          />
          <FormHelperText>
            {selectedView && formErrors.recordFilterField}
          </FormHelperText>
        </FormControl>
      </div>
      <div className="flex flex-col gap-4 capitalize">
        <FormControl>
          <InputLabel
            id="summary-type"
            disabled={!summaryField || !selectedTable || !selectedView}
          >
            Summary Type
          </InputLabel>
          <Select
            labelId="summary-type"
            label="Summary Type"
            value={summaryType}
            disabled={!summaryField || !selectedTable || !selectedView}
            onChange={(event) => {
              setSummaryType(event.target.value as SummaryType)
            }}
          >
            {summaryOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {summaryNiceName(option)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

export default FieldSummarySettings
